import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './InputDropdown.css';


export default function InputDropdownMultiple(props) {

    const { value, disabled, required, id } = props;

    const handleChange = (e, { value }) => {
        props.onChange(value);
    };

    const renderLabel = label => ({
        color: 'blue',
        content: `${label.text}`
    });

    return (
        <Dropdown
            id={`dropdown_${id || Date.now()}`}
            className="InputDropdown"
            noResultsMessage={'Результатов не найдено'.t}
            placeholder={props.placeholder}
            fluid
            required={required}
            disabled={disabled}
            multiple={true}
            value={value}
            onChange={handleChange}
            closeOnChange
            selection
            options={props.options || []}
            renderLabel={renderLabel}
        />
    );
}

