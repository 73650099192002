import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Message, Modal, TextArea} from "semantic-ui-react";
import {rgEditOrders, rgReject} from "../../api/registries";
import {formatNumber, getValuePassZero, restrictionFloat} from "../../services/utils";

const RgRejectModal = ({ open, onClose, fmid, registryNumber, ids, fetchData, isEdit, params: editParams = {} }) => {
    let [params, setParams] = useState(editParams);
    let [progress, setProgress] = useState(false);

    useEffect(() => {
        open && setParams(editParams);
    }, [open]);

    const handleClose = () => {
        setParams({});
        onClose();
    };

    const handleChange = (e, {value, name}) => {
        setParams(params => ({
            ...params,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setProgress(true);
        try {
            if (isEdit) {
                await rgEditOrders ({
                    ids,
                    ...params,
                })
            } else {
                await rgReject({
                    ids,
                    ...params,
                });
            }

            await fetchData();
        } finally {
            handleClose();
            setProgress(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{isEdit ? 'Редактирование Комментария и Общей стоимости без НДС' : 'Оставьте Комментарий и Общую стоимость без НДС'}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Message
                        info
                        icon='info'
                        content={isEdit ? <span>Вы можете оставить или изменить Комментарий и Общую стоимость без НДС по всей заявке.</span>
                            : <span>Оставьте Комментарий, почему не подтверждаете заявку <b>{fmid}</b>.
                            Общую стоимость без НДС по всей заявке Вы можете указать в отдельном поле.</span>}
                    />
                    <Form.Field>
                        <label>Комментарий</label>
                        <TextArea
                            placeholder='Введите комментарий'
                            value={params.comment}
                            maxLength={1000}
                            name="comment"
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Стоимость без НДС</label>
                        <Input
                            type="number"
                            min="0"
                            step="0.1"
                            placeholder={'Стоимость без НДС'.t}
                            value={getValuePassZero(params.clientCostWithoutVAT)}
                            onChange={e => handleChange(e, {name: 'clientCostWithoutVAT', value: restrictionFloat(e.target.value, 3)})}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Отменить</Button>
                <Button color="green" loading={progress} disabled={!params.comment || progress} onClick={handleSubmit}>{isEdit ? 'Сохранить изменения' : 'Сохранить'}</Button>
            </Modal.Actions>
        </Modal>
    )
};

export default RgRejectModal;
