import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Checkbox,
    Divider,
    Feed,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    Label,
    Menu,
    Popup,
    Segment,
    Step,
    Table
} from "semantic-ui-react";
import useReactRouter from "use-react-router";
import Shield from "../../layout/page/shield/Shield";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import RgRejectModal from "./RgRejectModal";
import {
    downloadFilePod,
    getCard,
    getRgHistory,
    rgConfirmOrders,
    rgExportToExcel,
    rgGetSources,
    rgInWork,
    rgSendFM
} from "../../api/registries";
import {RegistryOrderStatusOptions} from "../../api/model/registries";
import iconClearFilters from '../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png';
import T from "../../components/Translate";
import {
    headers1,
    headers1Ext,
    headers2,
    headers3,
    headersComment,
    headersLoadUnits,
    headersServices,
    headersTrip,
} from "./headersConst";
import RgSendModal from "./RgSendModal";
import qs from "query-string";
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import WbGridFilterList from "../waybills/wbGrid/wbGridFilters/WbGridFilterList";
import WbGridFilterDatePeriod from "../waybills/wbGrid/wbGridFilters/WbGridFilterDatePeriod";
import O from "../../components/Option";
import {StepData} from "../waybills/wbView/StepInfo";
import HistoryItem from "../_shared/HistoryItem";
import StepContent from "../../components/steps/StepContent";
import RgStatus from "./RgStatus";
import downloadFile from "../../services/downloadFile";
import {styleTh} from "../../services/utils";

const RgCard = ({ location }) => {
    const contextNavi = useContext(ContextNavi);
    const {history, match = {}} = useReactRouter();
    const {params} = match;

    let [card, setCard] = useState({
        registry: {},
        orders: []
    });
    let [headers, setHeaders] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [loading, setLoading] = useState(true);
    let [openModal, setOpenModal] = useState({
        open: false
    });
    let [loadingInWork, setLoadingInWork] = useState(false);
    let [loadingSend, setLoadingSend] = useState(false);

    let [selectedIds, setSelectedIds] = useState(new Set());
    let [confirmProgress, setConfirmProgress] = useState(false);

    let [openSendModal, setOpenSendModal] = useState({
        open: false
    });

    let [sources, setSources] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const card = await getCard(params.id, location.search);

            setCard(card);

            let mapHeadersServices = [];

            const services = [];

            headersServices.forEach(servicesItem => {
                const servicesMap = card.services
                    ? card.services.find(item => item.type === servicesItem.key)
                    : {};

                services.push({
                    ...servicesItem,
                    ...servicesMap
                });
            });

            services.forEach(item => {
                if (item.count) {
                    mapHeadersServices.push({
                        ...item,
                        type: item.key,
                        isServices: true
                    });
                }
                if (item.price) {
                    mapHeadersServices.push({
                        key: `cost_${item.key}`,
                        text: `${item.text}, стоимость`,
                        type: item.key,
                        title: item.title ? `${item.title}, стоимость` : null,
                        isServices: true,
                        isPrice: true
                    });
                }
            });

            let mapHeadersTrip = headersTrip.filter(item => card.trips[item.key]);

            let mapHeadersLoadUnits = [];
            const units = [];

            headersLoadUnits.forEach(unitsItem => {
                const unitsMap = card.units
                    ? card.units.find(item => item.type === unitsItem.key)
                    : {};

                units.push({
                    ...unitsItem,
                    ...unitsMap
                });
            });

            units.forEach(item => {
                if (item.countPlan) {
                    mapHeadersLoadUnits.push({
                        ...item,
                        text: `${item.text}, кол-во план`,
                        type: item.key,
                        isUnits: true,
                        isPlan: true
                    });
                }
                if (item.countFact) {
                    mapHeadersLoadUnits.push({
                        key: `fact_${item.key}`,
                        text: `${item.text}, кол-во факт`,
                        type: item.key,
                        isUnits: true,
                        isPlan: false
                    });
                }
            });

            const withMainFmId = !!(card.orders || []).find(o => o.mainfmid);

            setHeaders([
                ...(withMainFmId ? headers1 : headers1.filter(h => h.key !== 'mainfmid')),
                ...mapHeadersLoadUnits,
                ...headers1Ext,
                ...mapHeadersTrip,
                ...headersComment,
                ...mapHeadersServices,
                ...headers2
            ]);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    const getSources = async () => {
        const sources = await rgGetSources(params.id);
        let sourcesObj = {};

        sources.forEach(item => {
            sourcesObj = {
                ...sourcesObj,
                [item.name.toLowerCase()]: item.values.map(value =>  new O(value, value)),
            }
        });

        setSources(sourcesObj);
    };

    useEffect(() => {
        contextNavi.setItems([
            new LinkInfo("Реестры", "/registries"),
            new LinkInfo(card.registry.registryNumber, "/registries/:id")
        ]);
    }, [card]);

    useEffect(() => {
        fetchData();
    }, [location.search]);

    useEffect(() => {
        getSources();
    }, []);

    const handleBack = () => {
        history.push("/registries");
    };

    const getCell = (column, row) => {
        if (column.isUnits) {
            const unit = row.units.find(item => item.type === column.type) || {};
            if (column.isPlan) {
                return unit.countPlan || "";
            } else {
                return unit.countFact || "";
            }
        }
        if (column.isServices) {
            const service = row.services.find(item => item.type === column.type);
            if (service) {
                if (column.isPrice) {
                    return service.price;
                } else {
                    return service.count;
                }
            }
            return "";
        }
        if (column.key === "state") {
            const o = RegistryOrderStatusOptions.find(o => o.key === row[column.key]);

            return o ? (
                <Label
                    color={o.color}
                    style={{textAlign: "center", marginRight: "5px"}}
                    title={o.text.t}
                >
                    {o.text.t}
                </Label>
            ) : null;
        }

        const FM_COMMENT = 'fmComment';

        if (column.key === FM_COMMENT || column.key === "clientComment") {
            const comment = column.key === FM_COMMENT
                ? (row.fmid
                    ? card.orders
                        .filter(item => item.fmid === row.fmid)
                        .map(item => item[FM_COMMENT])
                        .join(", ")
                    : row[FM_COMMENT])
                : row[column.key];
            return (
                <div
                    style={{
                        width: (comment || '').length >= 60 ? "400px" : "auto",
                        whiteSpace: ((comment || '').length >= 60) && 'break-spaces'
                    }}
                >
                    <span title={comment && comment.toString()}>{comment}</span>
                </div>
            );
        }

        if ((column.key === 'fmidc' && row.waybillId) || column.key === 'mainfmid') {
            return (
                <div
                    className="table-link table-link-green"
                    onClick={() => {
                        history.push(`/waybills/${column.key === 'mainfmid' ? row.mainId : row.waybillId}/view/info`);
                    }}
                >
                    {row[column.key]}
                </div>
            );
        }

        if (column.key === 'document') {
            const handleDownload = async () => {
                const result = await downloadFilePod(row.id);
                downloadFile(result);
            };

            return row.document
                ? <span className="a-underline-hover table-document" onClick={e => {
                    handleDownload();
                }}>
                    <T>Скачать POD</T>
                </span> : null;
        }

        return (
            <div
                style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            >
                <span title={row[column.key] && row[column.key].toString()}>
                  {row[column.key]}
                </span>
            </div>
        );
    };

    const getFilter = (key) => {
        switch (key) {
            case "fmidc":
            case "mainfmid":
            case "fmid":
            case "recipientOrderNo":
            case "torg12No":
            case "consignor":
            case "consignee":
            case "loadingComment":
            case "unloadingComment":
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'type':
            case 'cargoType':
            case 'serviceType':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={sources[key.toLowerCase()] || []} />;
            case 'loadingDate':
            case 'unloadingDate':
                return <WbGridFilterDatePeriod
                    minWidth
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter} />;
            default:
                return <div
                    className="filter-flex-container" />;
        }
    };

    const handleConfirm = async ids => {
        setConfirmProgress(true);
        try {
            await rgConfirmOrders(ids);
            await fetchData();
        } finally {
            setConfirmProgress(false);
        }

    };

    const massConfirm = async () => {
        setConfirmProgress(true);
        try {
            await rgConfirmOrders(Array.from(selectedIds));
            await fetchData();
        } finally {
            setConfirmProgress(false);
        }
    };

    const handleReject = (fmid, ids) => {
        setOpenModal({
            open: true,
            fmid,
            registryNumber: card.registry.registryNumber,
            ids
        });
    };

    const notMassConfirm = useMemo(() => {
        if (!selectedIds.size) {
            return true;
        } else {
            return !card.orders.filter(
                item => selectedIds.has(item.id) && item.isAvailableConfirm
            ).length;
        }
    }, [selectedIds, card]);

    const styleDisabledHeader = {
        background: "#f9fafb",
        cursor: "default"
    };

    const deselect = () => {
        if (selectedIds.size) {
            setSelectedIds(new Set());
        } else {
            setSelectedIds(new Set(card.orders.map(item => item.id)));
        }
    };

    const handleRowSelection = ids => {
        const newSelectedIds = new Set(selectedIds);

        ids.forEach(id => {
            newSelectedIds[!selectedIds.has(id) ? "add" : "delete"](id);
        });

        setSelectedIds(newSelectedIds);
    };

    const handleEdit = (fmid, ids, params) => {
        setOpenModal({
            open: true,
            fmid,
            registryNumber: card.registry.registryNumber,
            ids,
            isEdit: true,
            params
        });
    };

    const handleInWork = async () => {
        setLoadingInWork(true);
        try {
            await rgInWork(card.registry.id);
            await fetchData();
        } finally {
            setLoadingInWork(false);
        }
    };

    const handleSend = async () => {
        setOpenSendModal({
            open: true,
            onClose: () => {
                setOpenSendModal({
                    open: false
                });
            },
            onSend: async () => {
                setConfirmProgress(true);
                await rgSendFM(card.registry.id).then(() => setConfirmProgress(false));
                setOpenSendModal({
                    open: false
                });
                await fetchData();
            }
        });
    };

    let query = qs.parse(location.search);

    const { sortBy, sortDir } = query;

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let { sortBy, sortDir } = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({ ...query, sortBy, sortDir });
    };

    function applyFilter(filter) {
        updateQuery({ ...query, ...filter });
        selectedIds.size && setSelectedIds(new Set());

    }

    function removeFilter(key) {
        updateQuery({ ...query, [key]: undefined });
        selectedIds.size && setSelectedIds(new Set());
    }

    function clearFilters() {
        updateQuery({});
        selectedIds.size && setSelectedIds(new Set());
    }

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{ paddingTop: '5px', display: 'inline-block', width: '17px' }}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{ height: '13px' }} />
        </span>
    );

    const handleExportToExcel = async () => {
       const fileInfo = await rgExportToExcel(card.registry.id, Array.from(selectedIds), query);
        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    const isHighlight = (row, {key, type, isPrice}) => {
        const change = row.changes.find(item => item.name.toLowerCase() === key.toString().toLowerCase());
        if (change) return change.changed;

        const serviceChange = row.serviceChanges.find(item => item.type === type);

        if (serviceChange) return isPrice ? serviceChange.priceChanged : serviceChange.countChanged;

        return false;
    };

    const [tabKeyActive, setTabKeyActive] = useState('info');
    const [rgHistory, setRgHistory] = useState([]);

    const getHistory = async () => {
        if (tabKeyActive === 'history') {
            const res = await getRgHistory(card.registry.id);
            setRgHistory(res);
        }
    };

    useEffect(() => {
        getHistory();
    }, [tabKeyActive]);

    const stepParams = key => {
        return {
            key: key,
            link: true,
            active: tabKeyActive === key,
            onClick: () => setTabKeyActive(key)
        };
    };

    const allSteps = useMemo(() => {
        let steps = [
            <Step {...stepParams('info')}>
                <StepContent icon="info" title="Реестр" />
            </Step>,
        ];

        steps.push(<Step {...stepParams("history")}>
            <StepContent icon="history" title="История" />
        </Step>);

        return steps;
    }, [stepParams]);

    const isDate = (key) => {
        const dateKeys = ['loadingDate', 'unloadingDate'];
        return dateKeys.includes(key);
    };

    const heightTableHeader = () => {
        let header = document.getElementById('tableHeader');
        return header && header.clientHeight;
    };

    const actionsView = (rows) => {
        return !!rows.find(row => row.isAvailableConfirm || row.isAvailableReject || row.isAvailableEditComment);
    };

    const colStatusClass = !actionsView(card.orders) ? 'table-registry-fixed__without-actions' : '';

    const steps = [
        new StepData(
            () => (
                <>
                    <Menu
                        className="waybills-toolbar shd-inset"
                        style={{marginBottom: "0"}}
                        size="small"
                        borderless
                    >
                        <RgStatus status={card.registry.state} className='m-auto m-l-15'/>
                        <Menu.Menu position="right">
                            <Menu.Item
                                loading={loadingSend}
                                onClick={handleSend}
                                disabled={!card.registry.isAvailableSend}
                            >
                                <Icon color="green" name="paper plane"/>
                                <T>Отправить в FM</T>
                            </Menu.Item>
                            <Menu.Item
                                loading={loadingInWork}
                                onClick={handleInWork}
                                disabled={!card.registry.isAvailableWork || loadingInWork}
                            >
                                <Icon color="green" name="play circle"/>
                                <T>Взять в работу</T>
                            </Menu.Item>
                            <Menu.Item
                                loading={confirmProgress}
                                onClick={massConfirm}
                                disabled={notMassConfirm || confirmProgress}
                            >
                                <Icon name="check circle outline"/>
                                <T>Подтвердить массово</T>
                            </Menu.Item>
                            <Menu.Item onClick={handleExportToExcel}>
                                <Icon name="arrow up"/>
                                <T>Экспорт реестра в Excel</T>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <div className="table-wrapper-grid table-wrapper-grid_w-100">
                        <div className="table-scroll-grid">
                            <Table celled singleLine sortable className="rg-card">
                                <Table.Header className="table-header-fixed">
                                    <Table.Row id='tableHeader'>
                                        <Table.HeaderCell
                                            className="table-first-col table-item-sticky table-registry-select-all"
                                            style={{zIndex: "6"}}
                                        >
                                            <Checkbox
                                                checked={selectedIds.size === card.orders.length}
                                                indeterminate={
                                                    selectedIds.size &&
                                                    selectedIds.size !== card.orders.length
                                                }
                                                onChange={deselect}
                                            />
                                        </Table.HeaderCell>
                                        {[...headers, ...headers3].map(item => (
                                            <Table.HeaderCell
                                                title={item.title}
                                                sorted={item.sortable && sortBy === item.key ? sortDir : null}
                                                style={item.sortable ? null : styleDisabledHeader}
                                                onClick={item.sortable ? handleSort(item.key) : null}
                                                className={
                                                    item.key === "fmidc" || item.key === "state"
                                                        ? `table-select-cell table-registry-header-fixed__title-${item.key} ${colStatusClass} ${isDate(item.key) ? "style-date" : ""}`
                                                        : `table-select-cell table-header-fixed__title ${isDate(item.key) ? "style-date" : ""}`
                                                }
                                                key={item.key}
                                            >
                                                <div style={styleTh(item.text)}>{item.text}</div>
                                            </Table.HeaderCell>
                                        ))}
                                        {actionsView(card.orders) && <Table.HeaderCell
                                            className="table-select-cell table-registry-header-fixed__title-actions"
                                            style={styleDisabledHeader}
                                        >
                                            Действия
                                        </Table.HeaderCell>}
                                    </Table.Row>
                                    <Table.Row key="filters" style={{height: "37px"}}>
                                        <Table.Cell
                                            className="table-first-col table-item-sticky table-registry-filter-clear"
                                            style={{ backgroundColor: '#f9fafb', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px', top: heightTableHeader()+'px' }}>
                                            <Popup content={"Очистить все фильтры".t} trigger={clearFilterButton} />
                                        </Table.Cell>
                                        {[...headers, ...headers3].map(({ key, source }) => (
                                            <Table.Cell
                                                key={key}
                                                sorted={sortBy === key ? sortDir : null}
                                                style={{background: '#fff;', top: heightTableHeader()+'px'}}
                                                className={
                                                    key === "fmidc" || key === "state"
                                                        ? `table-select-cell table-registry-filter-fixed__title-${key} ${colStatusClass}`
                                                        : "table-select-cell table-filter-fixed__title"
                                                }
                                            >
                                                {getFilter(key, source)}
                                            </Table.Cell>
                                        ))}
                                        {actionsView(card.orders) && <Table.Cell
                                            style={{background: '#fff;', top: heightTableHeader()+'px'}}
                                            className="table-select-cell table-registry-filter-fixed__title-actions"
                                        />}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {card.orders &&
                                        card.orders.map(row => (
                                            <Table.Row key={row.id} active={selectedIds.has(row.id)}>
                                                {(row.fmid ? (
                                                    card.orders
                                                        .filter(item => item.fmid === row.fmid)
                                                        .findIndex(item => item.id === row.id) === 0
                                                ) : (
                                                    card.orders
                                                )) ? (
                                                    <Table.Cell
                                                        className="table-first-col table-item-sticky"
                                                        rowSpan={
                                                            row.fmid
                                                                ? card.orders.filter(
                                                                    item => item.fmid && item.fmid === row.fmid
                                                                ).length
                                                                : 1
                                                        }
                                                    >
                                                        <Checkbox
                                                            id={row.id}
                                                            checked={selectedIds.has(row.id)}
                                                            onChange={() =>
                                                                handleRowSelection(
                                                                    row.fmid
                                                                        ? card.orders
                                                                            .filter(item => item.fmid === row.fmid)
                                                                            .map(item => item.id)
                                                                        : [row.id]
                                                                )
                                                            }
                                                        />
                                                    </Table.Cell>
                                                ) : null}

                                                {headers.map(column => (
                                                    <Table.Cell
                                                        className={`${column.key === "fmidc" || column.key === "state"
                                                            ? `table-registry-${column.key}-sticky ${colStatusClass}`
                                                            : ''} ${isHighlight(row, column) ? 'registry_cell_highlight' : ''}`}
                                                        key={`${column.key}_${row.id}`}
                                                    >
                                                        {getCell(column, row)}
                                                    </Table.Cell>
                                                ))}
                                                {headers3.map(column => (
                                                    <>
                                                        {(row.fmid ? (
                                                            card.orders
                                                                .filter(item => item.fmid === row.fmid)
                                                                .findIndex(item => item.id === row.id) === 0
                                                        ) : (
                                                            card.orders
                                                        )) ? (
                                                            <Table.Cell
                                                                className={
                                                                    column.key === "fmidc" || column.key === "state"
                                                                        ? `table-registry-${column.key}-sticky ${colStatusClass}`
                                                                        : null
                                                                }
                                                                rowSpan={
                                                                    row.fmid
                                                                        ? card.orders.filter(
                                                                            item => item.fmid && item.fmid === row.fmid
                                                                        ).length
                                                                        : 1
                                                                }
                                                                key={`${column.key}_${row.id}`}
                                                            >
                                                                {getCell(column, row)}
                                                            </Table.Cell>
                                                        ) : null}
                                                    </>
                                                ))}
                                                {(row.fmid ? (
                                                    card.orders
                                                        .filter(item => item.fmid === row.fmid)
                                                        .findIndex(item => item.id === row.id) === 0
                                                ) : (
                                                    card.orders
                                                )) ? (actionsView(card.orders) &&
                                                    <Table.Cell
                                                        className="table-registry-action-sticky"
                                                        rowSpan={
                                                            row.fmid
                                                                ? card.orders.filter(
                                                                    item => item.fmid && item.fmid === row.fmid
                                                                ).length
                                                                : 1
                                                        }
                                                    >
                                                        {row.isAvailableConfirm && (
                                                            <Icon
                                                                disabled={confirmProgress}
                                                                name="check square outline"
                                                                link
                                                                title="Подтвердить"
                                                                size="big"
                                                                color="green"
                                                                onClick={() =>
                                                                    handleConfirm(
                                                                        row.fmid
                                                                            ? card.orders
                                                                                .filter(item => item.fmid === row.fmid)
                                                                                .map(item => item.id)
                                                                            : [row.id]
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {row.isAvailableReject && (
                                                            <Icon
                                                                name="exclamation close"
                                                                link
                                                                className="m-l-15"
                                                                title="Не подтверждать"
                                                                size="big"
                                                                color="red"
                                                                onClick={() =>
                                                                    handleReject(
                                                                        row.type === 'd' ? row.fmidc : row.fmid,
                                                                        row.fmid
                                                                            ? card.orders
                                                                                .filter(item => item.fmid === row.fmid)
                                                                                .map(item => item.id)
                                                                            : [row.id]
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {row.isAvailableEditComment && (
                                                            <Icon
                                                                name="pencil alternate"
                                                                link
                                                                className="m-l-15"
                                                                title="Редактировать"
                                                                size="big"
                                                                color="red"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        row.type === 'd' ? row.fmidc : row.fmid,
                                                                        row.fmid
                                                                            ? card.orders
                                                                                .filter(item => item.fmid === row.fmid)
                                                                                .map(item => item.id)
                                                                            : [row.id],
                                                                        {
                                                                            comment: row.clientComment,
                                                                            clientCostWithoutVAT: row.clientCostWithoutVAT
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                ) : null}
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </>
            ),
            'info',
            'Реестр', null
        ),
        new StepData(() =>
            (<Segment className="h-100">
                <div className="flex-col h-100">
                    <Grid style={{minHeight: '95px'}} verticalAlign='middle'>
                        <GridRow columns={3}>
                            <GridColumn>
                                <RgStatus status={card.registry.state}/>
                            </GridColumn>
                            <GridColumn textAlign="center">
                                <Header>
                                    <Header.Content>
                                        {step.text}
                                    </Header.Content>
                                </Header>
                            </GridColumn>
                            <GridColumn/>
                        </GridRow>
                    </Grid>
                    <Divider className="m-t-0" />
                    <div className="flex-grow scrollable-v">
                        <Feed>
                            {rgHistory.map(i => <HistoryItem {...i} />)}
                        </Feed>
                    </div>
                </div>
            </Segment>),
            'history', 'История', null),
    ];

    const step = steps.find(s => s.index === tabKeyActive) || {};

    return (
        <Shield loading={loading} loadingOver={loaded}>
            <Grid columns={2} className="p-t-0 m-t-0 h-100">
                <Grid.Row stretched className="p-t-0 p-b-0 m-t-1-negative h-100">
                    <Grid.Column
                        className="p-b-0 p-r-0 m-r-1-negative steps-mini"
                    >
                        <Step.Group fluid vertical size="tiny" className="m-b-0 steps-grey">
                            <div className="step-wrapper">
                                {allSteps.map(item => item)}
                            </div>
                        </Step.Group>
                    </Grid.Column>
                    <Grid.Column className="p-l-0 p-b-0 h-100 step-data">
                        {step.component()}
                        <RgRejectModal
                            {...openModal}
                            fetchData={fetchData}
                            onClose={() => setOpenModal({open: false})}
                        />
                        <RgSendModal {...openSendModal} confirmProgress={confirmProgress} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Shield>
    );
};

export default RgCard;
