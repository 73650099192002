import React, {useContext, useEffect, useRef, useState} from 'react';
import Shield from "../../layout/page/shield/Shield";
import {Dropdown, Form, Grid, Icon, Image, Menu, Table} from "semantic-ui-react";
import T from "../../components/Translate";
import ScheduleShema from "./scheduleShema";
import {exportViews, getFilters, getSchedulesView} from "../../api/schedules";
import O from '../../components/Option';
import './styles.css';

import AwesomeDebouncePromise from "awesome-debounce-promise";
import {CargoTypeOptions} from "../../api/model/Enums";
import {ContextFooter} from "../../services/context";
import ScrollPreloader from "../../components/miscs/ScrollPreloader";
import {arraysHaveCommonValues, intersect} from "../../services/utils";
import {toast} from "../../services/toast";

const searchDebounced = AwesomeDebouncePromise((search) => search(), 1000);

const SchedulesList = () => {

    let [loading, setLoading] = useState(false);
    let [filters, setFilters] = useState({});
    let [rows, setRows] = useState([]);
    let [rowsCount, setRowsCount] = useState(0);
    let [filterOptions, setFilterOptions] = useState({});
    const contextFooter = useContext(ContextFooter);
    const page = useRef(0);
    const scrollComponent = useRef(null);
    const pageSize = 20;

    const [scrollLoading, setScrollLoading] = useState(false);
    const [isMaxRows, setIsMaxRows] = useState(false);

    const mounted = useRef();

    useEffect(() => {
        setRowsCount(countSchedules(rows));
    }, [rows]);

    const countSchedules = (rows) => {
        let count = 0;
        rows.forEach(r => count += r.consolidationWarehouse.schedules.length);
        return count;
    };

    const needScroll = rowsCount >= pageSize && rowsCount % pageSize === 0 && !isMaxRows;

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            getSchelules();
            getFiltersOptions();
        }
    }, []);

    useEffect(() => {
        changeFilters();
    }, [filters]);

    useEffect(() => {
        getFiltersOptions();
    }, []);

    const changeFilters = async () => {
        scrollTop();
        setIsMaxRows(false);
        await searchDebounced(getSchelules);
    };

    const getSchelules = async () => {
        let f = {};
        Object.keys(filters).forEach(k => filters[k] ? f[k] = filters[k] : null);
        const result = await getSchedulesView(f);
        if ((!result || !result.length) && Object.keys(f).length) {
            toast.warning('В случае, если Вы не нашли подходящего графика доставки груза в адрес грузополучателя, необходимо выбрать вариант "Другие грузополучатели", и система подберет актуальные волны доставки');
        }
        setRows(result);
    };

    const getFiltersOptions = async () => {
        const result = await getFilters(filters.destinationRegions);

        setFilterOptions({
            chains: filterOptions.chains ? filterOptions.chains : result.chains.map(item => ({
                key: item.name,
                value: {
                    pointType: item.pointType,
                    companyName: item.companyName,
                    networkId: item.network ? item.network.id : null,
                },
                text: item.name
            })),
            consignees: result.consignees.map(item => ({
                key: item.consignee,
                value: item.consignee,
                text: item.consignee,
                networks: item.networks,
            })),
            sendingRegions: result.sendingRegions.map(item => new O(item.value, item.value, item.address)),
            destinationCities: result.destinationCities.map(item => ({
                key: `${item.name}_${item.destinationRegion}`,
                value: item.name,
                text: item.name,
                networks: item.networks,
                consignees: item.consignees,
                destinationRegion: item.destinationRegion,
            })),
            destinationRegions: result.destinationRegions.map(item => ({
                key: item.name,
                value: item.name,
                text: item.name,
                networks: item.networks,
                consignees: item.consignees,
            })),
            cargoTypes: result.cargoTypes.map(item => new O(item, CargoTypeOptions.getText(item)))
        });
    };

    const filtersWithoutInappropriateValues = (filters) => {
        const chains = filters?.chains?.filter(c => getFilterOptions('chains', filters)
            .map(f => f.value?.networkId).includes(c.networkId));
        const consignees = filters?.consignees?.filter(c => getFilterOptions('consignees', filters)
            .map(f => f.value).includes(c));
        const destinationRegions = filters?.destinationRegions?.filter(c => getFilterOptions('destinationRegions', filters)
            .map(f => f.value).includes(c));
        const destinationCities = filters?.destinationCities?.filter(c => getFilterOptions('destinationCities', filters)
            .map(f => f.value).includes(c));
        return {
            ...filters,
            chains: chains?.length ? chains : null,
            consignees: consignees?.length ? consignees : null,
            destinationRegions: destinationRegions?.length ? destinationRegions : null,
            destinationCities: destinationCities?.length ? destinationCities : null,
        };
    };

    const handleFiltersChange = (e, {name, value}) => {
        if (name === 'destinationRegions') {
            setFilters(filters => filtersWithoutInappropriateValues({
                ...filters,
                [name]: value,
                destinationCities: null,
            }));
        } else {
            const othersValue = filterOptions.consignees?.find(c => !c.networks[0]?.networkId)?.value;
            let values = {};
            if (name === 'chains' && value) {
                values.consignees = filters.consignees || [];
                if (value.find(v => !v.networkId)) {
                    values.consignees = [
                        othersValue,
                        ...(filters.consignees || []).filter(c => c !== othersValue)
                    ];
                } else values.consignees = values.consignees.filter(i => i !== othersValue);
            }
            if (name === 'consignees' && value) {
                if (value.find(v => v === othersValue)) {
                    values.chains = [
                        filterOptions.chains?.find(c => !c.value?.networkId)?.value,
                        ...(filters.chains || []).filter(c => c.networkId)
                    ];
                }
            }

            setFilters(filters => filtersWithoutInappropriateValues({
                ...filters,
                [name]: value,
                ...values
            }));
        }
    };

    const handleExportClick = async () => {
        const fileInfo = await exportViews({
            filter: filters
        });


        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank", 'noreferrer');
        }
    };

    const recommendedTimeView = (schedule) => {
        return schedule.recommendedTime && (schedule.recommendedTime.from || schedule.recommendedTime.to);
    };

    useEffect(() => {
        contextFooter.setIndicator(
            () => <div className="table-footer">
                {rowsCount} записей
            </div>
        );
        return (() => {
            contextFooter.setIndicator(null);
        });
    }, [rowsCount]);

    const concatRows = (currentRows, nextRows) => {
        let newRows;
        const rowsLast = currentRows[currentRows.length - 1];
        const nextRowsFirst = nextRows[0];

        if (rowsLast.consolidationWarehouse.address === nextRowsFirst.consolidationWarehouse.address) {
            let newRow = {...rowsLast};
            newRow.consolidationWarehouse.schedules = rowsLast.consolidationWarehouse.schedules.concat(nextRowsFirst.consolidationWarehouse.schedules);
            let newCurrentRows = [...currentRows];
            newCurrentRows.pop();
            let newNextRows = [...nextRows];
            newNextRows.shift();
            newRows = [...newCurrentRows, newRow, ...newNextRows];
        } else newRows = currentRows.concat(nextRows);

        return newRows;
    };

    async function onBottomVisible() {
        if (scrollLoading) return;
        const nextPage = page.current + 1;
        const filter = {...filters};
        filter.page = nextPage.toString();

        setScrollLoading(true);
        const nextRows = await getSchedulesView(filter);

        if (nextRows && nextRows.length) {
            const allRows = concatRows(rows, nextRows);
            setRows(allRows);
            page.current = nextPage;
        } else setIsMaxRows(true);
        setScrollLoading(false);
    }

    const scrollTop = () => {
        page.current = 0;
        scrollComponent.current.scrollTop = 0;
    };

    const clearFilters = () => {
        setFilters({});
    };

    const networksByFilterName = (key, fs) => {
        if (['chains'].includes(key))
            return (fs || filters)[key]?.map(v => v.networkId || null) || [];
        else {
            const networks = filterOptions[key]?.filter(f => (fs || filters)[key]?.includes(f.key)).map(f => f.networks);
            return [...new Set(networks?.flat().map(v => v.networkId))];
        }
    };

    const consigneesByFilterName = (key, fs) => {
        if (['consignees'].includes(key))
            return (fs || filters)[key] || [];
        else {
            const consignees = filterOptions[key]?.filter(f => (fs || filters)[key]?.includes(f.key)).map(f => f.consignees).filter(c => c);
            return [...new Set(consignees?.flat())];
        }
    };

    const networksOptions = (opts, filters) => {
        const networks = intersect([
            networksByFilterName('destinationCities', filters),
            networksByFilterName('destinationRegions', filters),
            networksByFilterName('consignees', filters),
        ]).map(v => v === '' ? null : v);
        return networks.length ? opts.filter(o => networks.includes(o.value?.networkId)) : opts;
    };

    const consigneesOptions = (opts, filters) => {
        const networks = intersect([
            networksByFilterName('destinationCities', filters),
            networksByFilterName('destinationRegions', filters),
            networksByFilterName('chains', filters)
        ]).map(v => v === '' ? null : v);
        const consignees = intersect([
            consigneesByFilterName('destinationRegions', filters),
            consigneesByFilterName('destinationCities', filters),
        ]);
        let result = opts || [];
        if (networks.length) result = result.filter(o => arraysHaveCommonValues(o.networks.map(n => n.networkId || null), networks));
        if (consignees.length) result = result.filter(o => consignees.includes(o.value));
        return result;
    };

    const regionsOrCitiesOptions = (opts, filters, isCities) => {
        const networks = networksByFilterName('chains', filters).map(v => v === '' ? null : v);
        const consignees = consigneesByFilterName('consignees', filters);
        let result = opts || [];
        if (networks.length) result = result.filter(o => arraysHaveCommonValues(o.networks.map(n => n.networkId), networks));
        if (consignees.length) result = result.filter(o => arraysHaveCommonValues(o.consignees, consignees));
        if (isCities) {
            const regions = (filters || {}).destinationRegions || [];
            if (regions.length) result = result.filter(o => regions.includes(o.destinationRegion));
        }
        return result;
    };

    const getFilterOptions = (filterName, defaultFilters) => {
        const fs = defaultFilters || filters;
        let opts = [...((filterOptions || {})[filterName] || [])];
        switch (filterName) {
            case 'chains':
                opts = networksOptions(opts, fs);
                break;
            case 'consignees':
                opts = consigneesOptions(opts, fs);
                break;
            case 'destinationRegions':
                opts = regionsOrCitiesOptions(opts, fs);
                break;
            case 'destinationCities':
                opts = regionsOrCitiesOptions(opts, fs, true);
                break;
            default:
                break;
        }
        return opts;
    };

    return (
        <Shield loading={loading}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size="small" borderless>
                <Menu.Menu position="right">
                    <Menu.Item onClick={clearFilters} disabled={Object.keys(filters).every(f => !filters[f])}>
                        <Icon.Group className="menu-item-group-icon">
                            <Icon name="filter"/>
                            <Icon corner name="close"/>
                        </Icon.Group><T>Очистить фильтры</T>
                    </Menu.Item>
                    <Menu.Item onClick={() => handleExportClick()}>
                        <Icon name="arrow up"/><T>Экспорт графиков в Excel</T>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            <div>
                <Form>
                    <Grid className="schedule_filters">
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Form.Field>
                                    <label>Сеть</label>
                                    <Dropdown
                                        options={getFilterOptions('chains')}
                                        placeholder="все"
                                        value={filters.chains || null}
                                        name="chains"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Грузополучатель</label>
                                    <Dropdown
                                        options={getFilterOptions('consignees')}
                                        placeholder="все"
                                        value={filters.consignees || null}
                                        name="consignees"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Регион отправки</label>
                                    <Dropdown
                                        options={filterOptions.sendingRegions}
                                        placeholder="все"
                                        value={filters.sendingRegions || null}
                                        name="sendingRegions"
                                        className="filter_description"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Регион доставки</label>
                                    <Dropdown
                                        options={getFilterOptions('destinationRegions')}
                                        placeholder="все"
                                        name="destinationRegions"
                                        value={filters.destinationRegions || null}
                                        fluid
                                        multiple
                                        search
                                        selection
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Город доставки</label>
                                    <Dropdown
                                        options={getFilterOptions('destinationCities')}
                                        placeholder="все"
                                        name="destinationCities"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        value={filters.destinationCities || null}
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Тип груза</label>
                                    <Dropdown
                                        options={filterOptions.cargoTypes}
                                        placeholder="все"
                                        name="cargoTypes"
                                        fluid
                                        multiple
                                        search
                                        selection
                                        value={filters.cargoTypes || null}
                                        onChange={handleFiltersChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
            <div className="table-wrapper-grid table-wrapper-grid-290" ref={scrollComponent}>
                <Table celled structured>
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            <Table.HeaderCell textAlign="center"
                                              className="table-header-fixed__title schedules-table-header">
                                Распределительный центр
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"
                                              className="table-header-fixed__title schedules-table-header">
                                График доставки
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"
                                              className="table-header-fixed__title schedules-table-header">
                                Рек. временное окно
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"
                                              className="table-header-fixed__title schedules-table-header">
                                Доп. информация
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            rows && rows.map(row => (
                                <React.Fragment key={row.id}>
                                    <Table.Row>
                                        <Table.Cell colSpan="5">
                                            <div className="f-s-15"><b>Адрес склада консолидации:</b></div>
                                            <div className="f-s-14">
                                                {row.consolidationWarehouse && row.consolidationWarehouse.address}
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    {
                                        row.consolidationWarehouse && row.consolidationWarehouse.schedules.map(schedule => (
                                            <Table.Row>
                                                <Table.Cell textAlign="center">
                                                    <>
                                                        <div className="p-b-10 f-s-15">
                                                            <u><b>{schedule.distributionCenter && schedule.distributionCenter.chain}</b></u>
                                                        </div>
                                                        <div className="f-s-14">
                                                            {schedule.distributionCenter && `${schedule.distributionCenter.consignee ? `${schedule.distributionCenter.consignee} - ` : ''}${schedule.distributionCenter.address}`}
                                                        </div>
                                                        {schedule.cargoTypes &&
                                                            <div className="display-flex flex-col--valign-center m-t-10">
                                                                <Image width={14} height={14}
                                                                       className="m-r-4 schedule-icon"
                                                                       src="/img/box.svg"/>
                                                                <div className="f-s-14 schedule-text__color_grey">
                                                                    {schedule.cargoTypes.map(type => CargoTypeOptions.find(t => t.key === type)).map(t => t.text[0].toUpperCase() + t.text.slice(1)).join(', ')}
                                                                </div>
                                                            </div>}
                                                    </>
                                                </Table.Cell>
                                                <Table.Cell style={{minWidth: '700px'}}>
                                                    <ScheduleShema points={schedule.points} transit={schedule.transit}/>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center" style={{minWidth: '120px', width: '12%'}}>
                                                    {recommendedTimeView(schedule) &&
                                                        <div className="display-flex flex-col--valign-center">
                                                            <Image width={14} height={14} className="m-r-4 schedule-icon"
                                                                   src="/img/clock.svg"/>
                                                            <div className="f-s-14">
                                                                {`${schedule.recommendedTime && schedule.recommendedTime.from ? schedule.recommendedTime.from.slice(0, 5) : ''} - ${schedule.recommendedTime && schedule.recommendedTime.to ? schedule.recommendedTime.to.slice(0, 5) : ''}`}
                                                            </div>
                                                        </div>}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center" style={{minWidth: '150px', width: '15%'}}>
                                                    <div className="f-s-13" style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}><span
                                                        title={schedule.additionalInformationForClient}>{schedule.additionalInformationForClient}</span>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </Table.Body>
                </Table>
                {needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous={true}/>}

            </div>
        </Shield>
    );
};

export default SchedulesList;
