import {get, post} from "../services/http";
import Point from "./model/Point";

/** @param {string} url */
export async function pointClientSearchNameUpdate(pointId, clientId, searchName) {
    try {
        return await post(`points/${pointId}/company/${clientId}/searchname`, {value: searchName});
    } catch (error) {
        return null;
    }
}


export async function pointMappingCodeUpdate(pointId, clientId, mappingCode) {
    try {
        return await post(`points/${pointId}/company/${clientId}/mappingcode`, {value: mappingCode});
    } catch (error) {
        return null;
    }
}

export async function getPointClientById(id, companyId) {
    const result = await get(`point/id/${id}/company/${companyId}`);
    return new Point(result);
}


export const getPlatformsRequest = async () => {
    return await get(`avisation/platforms`);
};

export const getActivitiesRequest = async (platformName) => {
    return await get(`avisation/platforms/${platformName}/activities`)
};
