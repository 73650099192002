import {Oc} from "./Waybill";

export const RegistryStatusEnum = {
    NEW: 0,
    IN_PROCESS: 1,
    IN_PROCESS_CLIENT: 10,
    IN_PROCESS_FM: 11,
    AWAITING_EDIT_FM: 20,
    COMPLETED: 30,
};

export const RegistryOrderStatusEnum = {
    CONFIRMED: 0,
    REJECTED: 1,
    CHANGED_FM: 2,
};

export const RegistryOrderStatusOptions = [
    new Oc(RegistryOrderStatusEnum.CONFIRMED, "Подтверждена", 'green'),
    new Oc(RegistryOrderStatusEnum.CHANGED_FM, "Изменено FM", "orange"),
    new Oc(RegistryOrderStatusEnum.REJECTED, "Не подтверждена", "red"),
];

export const RegistrieStatusOptions = [
    new Oc(RegistryStatusEnum.NEW, "Новый", "teal"),
    new Oc(RegistryStatusEnum.IN_PROCESS, "На согласовании", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_CLIENT, "На согласовании: Клиент", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_FM, "На согласовании: FM", "orange"),
    new Oc(RegistryStatusEnum.AWAITING_EDIT_FM, "Ожидает корректировки FM", "blue"),
    new Oc(RegistryStatusEnum.COMPLETED, "Согласован", "green"),
];

export const RegistrieStatusOptionsFromFilter = [
    new Oc(RegistryStatusEnum.NEW, "Новый", "teal"),
    new Oc(RegistryStatusEnum.IN_PROCESS_CLIENT, "На согласовании: Клиент", "yellow"),
    new Oc(RegistryStatusEnum.IN_PROCESS_FM, "На согласовании: FM", "orange"),
    new Oc(RegistryStatusEnum.AWAITING_EDIT_FM, "Ожидает корректировки FM", "blue"),
    new Oc(RegistryStatusEnum.COMPLETED, "Согласован", "green"),
];
