import React from 'react';
import { Form, Input, Divider } from 'semantic-ui-react';

import PointFormAddress from './PointFormAddress/PointFormAddress';
import T from '../../../../components/Translate';

const PointFormStep1 = ({ ve, data, hasPermissions, update, onAddressTabChange,
    addressDadata, setAddressDadata, addressManual, setAddressManual, activeAddressTab, setActiveAddressTab,
    fromWaybill, isClient
}) => {

    return <React.Fragment>
        <div>
            <PointFormAddress
                error={ve.includes("address")}
                errorMessage={"Введенный адрес некорректен".t}
                validationErrors={ve}
                address={data.address || {}}
                onChange={val => update({ address: val })}
                onAddressTabChange={onAddressTabChange}
                addressDadata={addressDadata}
                setAddressDadata={setAddressDadata}
                addressManual={addressManual}
                setAddressManual={setAddressManual}
                activeAddressTab={activeAddressTab}
                setActiveAddressTab={setActiveAddressTab}
            />
            <Divider />
            <Form.Group style={{ marginTop: 20 }} widths='equal'>
                {hasPermissions && !fromWaybill && !isClient && <>
                    <Form.Field required error={ve.includes("fmid")}>
                        <label><T>Краткое название (search name)</T></label>
                        <Input
                            placeholder={'Краткое название (search name)'.t}
                            value={data.fmid || ''}
                            onChange={e => update({ fmid: e.target.value.toUpperCase().trimStart() })}
                            onBlur={e => update({ fmid: e.target.value.trim() })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label><T>Зона по МСК</T></label>
                        <Input
                            placeholder={'Зона по МСК'.t}
                            value={data.zone || ''}
                            onChange={e => update({ zone: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label><T>ИНН</T></label>
                        <Input
                            placeholder={'ИНН'.t}
                            value={data.inn || ''}
                            maxLength="17"
                            onChange={e => update({ inn: e.target.value })}
                        />
                    </Form.Field>
                </>}
                {isClient && <Form.Field>
                    <label><T>Название для быстрого поиска</T></label>
                    <Input
                        placeholder={'Название для быстрого поиска'.t}
                        value={data.pointName || ''}
                        onChange={e => update({ pointName: e.target.value.trimStart() })}
                        onBlur={e => update({ pointName: e.target.value.trim() })}
                    />
                </Form.Field>}
            </Form.Group>
        </div>
    </React.Fragment>;
};

export default PointFormStep1;