import React, {useEffect, useState} from 'react';
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Button, Dropdown, Form} from "semantic-ui-react";
import {getCompanyOptionsBySearchUser, getUserCompanyOptions} from "../../../../api/users";
import {unique} from "../../../../services/utils";
import {wbUpdateCompany} from "../../../../api/waybills";

const WbGridClientEditModal = ({children, refresh, selectedRows}) => {
    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [clientId, setClientId] = useState(null);

    useEffect(() => {
       getCompanies();
    }, []);

    const getCompanies = async () => {
        let companyOptions = await getUserCompanyOptions();

        setCompanies(companyOptions);
    };

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
        setCompanies([]);
        setClientId(null);
        refresh();
    };

    function onChangeClientId(clientId) {
        setClientId(clientId);
    }

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length
            ? options.filter(
                opt =>
                    (opt.text && opt.text.toLowerCase().includes(q0)) ||
                    (opt.description && opt.description.toLowerCase().includes(q0)),
            )
            : [];
    }

    async function handleClientSearchChange(e, {searchQuery}) {

        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;

        if (hasSearch || searchQuery.length === 0) {
            let CompanyOptions = await getCompanyOptionsBySearchUser(searchQuery);
            //const existingIds = this.state.addresses.map(a => a.id);
            CompanyOptions = unique(companies.concat(CompanyOptions));

            if (CompanyOptions.length) {
                setCompanies(CompanyOptions);
            }
        }
    }

    const handleSend = async () => {
        await wbUpdateCompany({
            fmid: clientId,
            waybills: selectedRows.map(i => i.id)
        });
        handleClose();
    };

    return (
        <Modal
            open={open}
            trigger={children}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                Замена клиента...
            </Modal.Header>
            <Modal.Content>
                <b>Укажите клиента для замены во всех выбранных заявках</b>
                <br/>
                <Form className="p-t-15">
                    <Form.Field>
                        <Dropdown
                            noResultsMessage={'Результатов не найдено'.t}
                            selection
                            search={searchTextAndDescription}
                            value={clientId || ''}
                            onSearchChange={handleClientSearchChange}
                            onChange={(e, {value}) => onChangeClientId(value)}
                            className="InputDropdown"
                            placeholder={'Клиент'.t}
                            fluid
                            closeOnChange
                            options={companies || []}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button primary onClick={handleSend}>Заменить клиента</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default WbGridClientEditModal;
