import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Modal, Form } from 'semantic-ui-react';
import {pointsExportExcelClient, pointsImport, pointsImportClient, pointsImportMyPoints} from '../../../../api/points';
import T from '../../../../components/Translate';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import './PointsFormImport.css';

const ImportStatus = {
    START: 1,
    FILES_SELECTED: 2,
    IMPORTING: 3,
    SUCCESS: 4, // not used -> redirecting on success
    ERROR: 5,
};

const initial = {
    open: false,
    status: ImportStatus.START,
    acceptedFiles: null,
    autoexport: false,
    errors: ""
};


export default class PointsFormImport extends React.Component {

    state = {
        ...initial
    };

    handleOpen = () => this.setState({ open: true })
    handleClose = () => this.setState({ ...initial })

    onDrop(acceptedFiles) {
        this.setState({ status: ImportStatus.FILES_SELECTED, acceptedFiles });
    }

    async import() {

        const { acceptedFiles, autoexport } = this.state;
        const { isClient, isMyPoints = false, selectedCompany } = this.props;

        var formData = new FormData();

        formData.append('files', acceptedFiles[0]);

        const importMethod = isClient ? pointsImportClient : (isMyPoints ? pointsImportMyPoints : pointsImport);

        this.setState({ status: ImportStatus.IMPORTING }, async () => {

            try {

                let data = await importMethod(formData, autoexport, selectedCompany);

                if (isMyPoints && autoexport) {
                    const file = await pointsExportExcelClient('', data);
                    window.open(file.url, '_blank');
                }

                this.setState({ ...initial }, this.props.refresh);

            } catch (error) {

                this.handleClose();
            }

        });
    }

    render() {
        const { status, acceptedFiles, autoexport, errors } = this.state;
        const { isClient, isMyPoints = false, companyOptions, selectedCompany, setSelectedCompany } = this.props;

        const exportCheck = <Form.Checkbox
            className="m-b-10"
            label={'Экспортировать адреса сразу после импорта'.t}
            checked={autoexport}
            onChange={(e, item) => this.setState({ autoexport: item.checked })}
        />;

        const fileUrl = isClient ? 'points_import_client.xlsx ': (isMyPoints ? 'my_points_import.xlsx' : 'points_import.xlsx');

        const statusInitial = (
            <div>
                {!isClient && exportCheck}
                {isClient && companyOptions.length > 1 && <Form.Field>
                    <label><T>Выбрать клиента</T></label>
                    <InputDropdown
                        placeholder={"Выбрать грузополучателя".t}
                        options={companyOptions}
                        value={selectedCompany}
                        onChange={val => setSelectedCompany(val)}
                    />
                </Form.Field>}
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    multiple={false}
                    className='dropzone'
                >
                    <T>Перетащите файл с адресами или кликните на это поле</T>
                </Dropzone>
                <div className="m-t-15">
                    <a className="ui positive button"
                        href={`/files/${fileUrl}`}
                        target="_blank">
                        <i aria-hidden="true" className="download icon"></i>Скачать пример файла для импорта адресов</a>
                </div>
            </div>
        );

        const statusFilesSelected = <div>
            {!isClient && exportCheck}
            <p>{acceptedFiles && acceptedFiles.length && acceptedFiles[0].name}</p>
        </div>;
        const statusLoading = <p><T>Загрузка файлов...</T></p>;
        const statusError = <p><T>Ошибка импорта</T>: {errors}</p>;

        return (
            <Modal trigger={this.props.children}
                dimmer="inverted" size="small" closeIcon
                open={this.state.open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
            >
                <Modal.Header><T>Импорт адресов из файла</T></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {status === ImportStatus.START
                            ? statusInitial
                            : status === ImportStatus.FILES_SELECTED
                                ? statusFilesSelected
                                : status === ImportStatus.IMPORTING
                                    ? statusLoading
                                    : status === ImportStatus.SUCCESS
                                        ? ""
                                        : statusError
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={'Отмена'.t} onClick={this.handleClose} />
                    <Button loading={status === ImportStatus.IMPORTING}
                        icon='check'
                        disabled={status !== ImportStatus.FILES_SELECTED && !!selectedCompany}
                        positive
                        content={'Импортировать адреса'.t}
                        onClick={this.import.bind(this)} />
                </Modal.Actions>
            </Modal>
        );
    }
};
