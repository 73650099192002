import React from 'react';
import { Container, Label } from 'semantic-ui-react';
import {companyCreate, exportExcel, getCompanies, getCompaniesBySearch} from '../../../api/companies';
import { CompanyStatusOptions } from '../../../api/model/Company';
import AppToolbarButton from '../../../components/buttons/AppToolbarButton';
import TableSearch from '../../../components/miscs/TableSearch';
import O from '../../../components/Option';
import AppTable from "../../../components/tables/AppTable";
import AppTableHeader from "../../../components/tables/AppTableHeader";
import AppTableToolbar from '../../../components/tables/AppTableToolbar';
import { LinkInfo } from '../../../layout/navbar/Navbar';
import Page from '../../../layout/page/Page';
import { ContextNavi } from '../../../services/context';
import CompanyFormNew from './CompanyFormNew';
import UserPermissions from "../../../api/model/UserPermissions";
import CompaniesFilters from "./CompaniesFilters";


const headers = [
    new AppTableHeader("name", "Название", 6),
    new AppTableHeader("statusId", "Статус"),
    new AppTableHeader("idSAP", "SAP"),
    new AppTableHeader("inn", "ИНН"),
    new AppTableHeader("usersCount", "Пользователей"),
];

export default class Companies extends Page {
    static contextType = ContextNavi;

    state = {
        ...this.state,
        searchKey: 'name',
        filters: {}
    }

    setFilters = (filters) => this.setState({filters: filters});

    setSearchValue = (searchKey, searchValue, isDrop) => {
        if (searchValue.length < 3 && !isDrop) {
            alert("Введите не менее 3 символов для поиска");
        }
        else this.setState({searchKey, searchValue});
    };
    componentWillMount() {
        if (this.context.setItems) {
            this.context.setItems([
                new LinkInfo("Клиенты", "/companies"),
            ]);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filters !== this.state.filters) {
            this.handleSearch();
        }
        if (prevState.searchKey !== this.state.searchKey || prevState.searchValue !== this.state.searchValue) {
            if (Object.keys(this.state.filters).length !== 0) this.setFilters({});
            else this.handleSearch();
        }
    }

    async fetchData() {
        this.setState({ rows: await getCompanies() });
    }

    async create(data) {
        try {
            await companyCreate(data);
            await this.fetchData();
        } catch(error) {
            // there can be validation errors
            return false;
        }
    }

    getCompanyStatusColor(statusId) {
        switch (statusId) {
            case 0: return "blue";
            case 1: return null;
            default: return statusId;
        }
    }

    renderItem(item, key) {
        const val = item[key];

        switch (key) {
            case 'statusId':
                const option = CompanyStatusOptions.find(o => o.key === val);
                
                return <Label
                    key={key}
                    color={this.getCompanyStatusColor(val)}>
                    {option && option.text.t}
                </Label>;
            
            default:
                return val;
        }
    }

    getFilters = () => {
        let newFilters = {};

        for (let key in this.state.filters) {
            if (this.state.filters[key] && this.state.filters[key] !== '') newFilters[key] = this.state.filters[key].split(',');
        }

        return newFilters;
    }

    async handleSearch() {
        const newFilters = this.getFilters();

        if (Object.keys(newFilters).length === 0 && (!this.state.searchValue || this.state.searchValue === ''))
            this.fetchData();
        else {
            let searchKey = this.state.searchKey;
            let searchValue = this.state.searchValue || '';
            let rows;
            rows = await getCompaniesBySearch(searchKey, searchValue, newFilters);
            this.setState({rows});
        }
    }

    async excel() {
        const searchKey = this.state.searchKey;
        const searchValue = this.state.searchValue || '';
        const newFilters = this.getFilters();
        await exportExcel(searchKey, searchValue, newFilters).then(res => {
            if (!res.error) {
                window.open(res.url, '_blank');
            }
        });
    }

    renderData() {
        const permissions = this.props.permissions;

        return (
            <div className="bgn-white h-100">
                <Container className="h-100">
                    <div className="flex-col h-100">
                        <div style={{ minHeight: '53px' }}>
                            <AppTableToolbar>
                                <AppTableToolbar.Left>
                                    <TableSearch
                                        setState={this.setState.bind(this)}
                                        handleSearch={this.setSearchValue.bind(this)}
                                        hasSearch={!!this.state.searchValue && this.state.searchValue !== ''}
                                        searchKey={this.state.searchKey}
                                        options={[
                                            new O("name", "Название"),
                                            new O("inn", "ИНН"),
                                        ]}
                                    />
                                </AppTableToolbar.Left>
                                <AppTableToolbar.Right>
                                    <span className='m-r-15'>
                                        <AppToolbarButton
                                            icon="arrow up"
                                            onClick={this.excel.bind(this)}
                                        >
                                            Экспорт пользователей в Excel
                                        </AppToolbarButton>
                                    </span>
                                    <CompanyFormNew fetchData={this.fetchData.bind(this)}>
                                        <AppToolbarButton icon="plus">Добавить клиента</AppToolbarButton>
                                    </CompanyFormNew>
                                </AppTableToolbar.Right>
                            </AppTableToolbar>
                        </div>
                        <div className="flex-grow scrollable-v">
                            <AppTable
                                filters={
                                    <CompaniesFilters
                                        filters={this.state.filters}
                                        setFilters={this.setFilters}
                                        headers={headers}
                                    />
                                }
                                headers={headers}
                                rows={this.state.rows}
                                onRowClick={c => this.props.history.push(`/admin/companies/${c.fmid}/${permissions.includes(UserPermissions.COMPANIES) ? 'props' : 'cutoffBlock'}`)}
                                renderItem={this.renderItem.bind(this)}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}