import React, {useState} from 'react';
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Button, Confirm, Form} from "semantic-ui-react";
import {wbsServices, wbsServicesUpdate} from "../../../../api/waybills";
import PointCardTabServices from "../../wbEdit/WbPointCardTabsServices";
import {PointService, PointServiceOptions} from "../../../../api/model/PointService";

const WbGridClientEditModal = ({children, refresh, selectedRows}) => {
    const [open, setOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [services, setServices] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const disabledSave = () => {
        return !isChanged || !!(services.find(s=>PointServiceOptions.find(si=>si.key === s.key && si.valueType) && !s.value));
    }

    const getServices = async () => {
        const selectedIds = selectedRows.map(row=>row.id);
        const data = await wbsServices(selectedIds);
        const servicesWbs = [];
        data.services.map(ps => servicesWbs.push(new PointService(ps.type, ps.value)));
        setServices(servicesWbs);
        setCanEdit(!data.hasDifference);
        setOpen(true);
    };

    const updateService = (newServices) => {
        setIsChanged(true);
        setServices(newServices);
    }

    const handleOpen = () => {
        getServices();
    };

    const handleClose = () => {
        setOpen(false);
        setConfirmOpen(false);
        refresh();
    };

    const onClose = () => {
        isChanged ? setConfirmOpen(true) : handleClose();
    };

    const handleSend = async () => {
        await wbsServicesUpdate({
            waybillIds: selectedRows.map(row=>row.id),
            services: services.map(s => {
                return {type: s.key, value: s.value}
            })
        });
        handleClose();
    };

    const confirmUpdates = <>
        <Modal.Content>
            <b>Выбранные заявки {selectedRows.map(row=>row.id).join(', ')} отличаются по данным в значениях Сервисов</b>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button primary onClick={()=>setCanEdit(true)}>Обновить</Button>
        </Modal.Actions>
    </>

    const textForLabel = () => {
        let consignees = [];
        selectedRows.forEach(row => {
            const index = consignees.findIndex(c => c.name === row.consignee);
            if (index !== -1) consignees[index].ids = [...(consignees[index].ids || []), row.fmid];
            else consignees.push({
                name: row.consignee,
                ids: [row.fmid]
            });
        });
        return consignees.map(c => `${(c.ids || []).join(', ')} для грузополучателя ${c.name}`).join('; ');
    };

    const isRoute = selectedRows.find(r => ['c', 'o'].includes(r.waybillType));

    return (
        <Modal
            open={open}
            trigger={children}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            {canEdit ? <>
                <Modal.Header>
                    Редактировать Сервисы
                </Modal.Header>
                <Modal.Content>
                    <b>Укажите Сервисы для изменения в заявках {textForLabel()}</b>
                    <br/>
                    <Form className="p-t-15">
                        <PointCardTabServices
                            isRoute={isRoute}
                            isLoading={false}
                            services={services}
                            update={updateService}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose}>Отмена</Button>
                    <Button primary disabled={disabledSave()} onClick={handleSend}>Сохранить</Button>
                </Modal.Actions>
            </> : confirmUpdates}
            <Confirm
                open={confirmOpen}
                cancelButton={'Нет'}
                confirmButton={'Да'}
                content="Вы уверены, что не хотите сохранить изменения?"
                onCancel={handleSend}
                onConfirm={handleClose}
            />
        </Modal>
    );
};

export default WbGridClientEditModal;
