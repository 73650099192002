import React, { useState } from 'react';
import { Icon, Checkbox, Form, Input, Popup } from 'semantic-ui-react';


export default function TableSearch({handleSearch, hasSearch, options = []}) {

    const [searchKey, setSearchKey] = useState(options[0] && options[0].key);
    const [searchValue, setSearchValue] = useState("");

    const search = e => {
        if (e.key === 'Enter') { 
            document.activeElement.blur();
            handleSearch(searchKey, searchValue);
        }
    };

    function clearSearch(e) {
        setSearchValue("");
        handleSearch(searchKey, "", true);
    };

    const placeholder = options.find(o => o.key === searchKey).description || 'введите не менее 3 символов для поиска';

    const searchInput = (
        <Input
            icon
            iconPosition="left"
            transparent
            className="f-l"
            style={{ marginLeft: '9px', minWidth: '500px' }}
            placeholder={placeholder}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={search}
            value={searchValue}
        >
            <input style={{ fontSize: '14px' }}/>
            <Icon
                link={hasSearch}
                name={hasSearch ? "delete" : "search"}
                style={{ top: '1px' }}
                onClick={hasSearch ? clearSearch : null}
            />
        </Input>
    );

    return (
        <Popup
            trigger={searchInput}
            position='bottom left' wide
            on='focus'
        >
            <Form style={{ minWidth: '180px', paddingBottom: "10px"}}>
                <Form.Field>
                    <b>Искать по:</b>
                </Form.Field>
                {
                    options.map(o => (
                        <Form.Field key={o.key} title={o.description || ""}>
                            <Checkbox
                                radio
                                label={o.text.t}
                                name='checkboxRadioGroup'
                                value={o.key}
                                checked={searchKey === o.key}
                                onChange={(e, { value }) => value && setSearchKey(o.key)}
                            />
                        </Form.Field>
                    ))
                }
            </Form>
        </Popup>
    );
}