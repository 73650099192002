import O from "../../components/Option";
import {Checkbox, Dropdown} from "semantic-ui-react";
import {valueToString} from "../../services/utils";
import {BooleanOptions} from "../../api/model/Dictionaries";
import {InputTime} from "../../components/inputs/InputTime";
import React, {useState} from "react";

const Select = ({col, row, handleChange, separator = ';', multiselect, minLettersForSearch = 3, className}) => {
    const values = multiselect ? (row[col.key] || []).filter(f => f).map(v => new O(v, v)) : (row[col.key] ? [new O(row[col.key], row[col.key])] : []);
    const [options, setOptions] = useState(values);
    const [searchValue, setSearchValue] = useState('');

    const getOptions = async (e) => {
        let opts = options;
        const searchQuery = e.target.value;
        setSearchValue(searchQuery);
        if (multiselect && searchQuery.includes(separator)) {
            const parsedValues = searchQuery.split(separator).filter(f => f);
            opts = parsedValues.map(v => new O(v, v));
            handleChange(null, {name: col.key, value: parsedValues});
            setSearchValue('');
        } else if (searchQuery && searchQuery.length >= minLettersForSearch) {
            const data = await col.items(searchQuery);
            opts = data.map(v => new O(v.value || v.fmid, v.value || v.fmid)).concat(values);
        }
        setOptions(opts);
    };

    return <Dropdown
        className={className || "notifications__input"}
        noResultsMessage={'Результатов не найдено'.t}
        selection
        name={col.key}
        value={multiselect ? (row[col.key] || []).filter(f => f) : row[col.key]}
        onChange={multiselect ? handleChange : (e, {value, name}) => {
            handleChange(e, {value, name});
            setSearchValue('');
        }}
        fluid
        closeOnChange={!multiselect}
        multiple={multiselect}
        options={options}
        searchQuery={searchValue}
        search={() => options.filter(f => multiselect ? !(row[col.key] || []).includes(f.value) : row[col.key] !== f.value)}
        onSearchChange={getOptions}
    />;
};

export const EditField = ({col, row, handleChange, className}) => {
    const toIntegerNumber = (v) => {
        let value;
        if (v[0] === '-' && (col.min === undefined || col.min < 0)) value = v[0] + v.slice(1).replace(/[^\d]/g, '');
        else value = v.replace(/[^\d]/g, '');
        return value;
    };

    const onChange = (e) => {
        let value = e.target.value;
        !(col.isInteger && col.max && (toIntegerNumber(value) > col.max)) && handleChange(e, {
            value: (col.isInteger && value) ? toIntegerNumber(value) : value,
            name: col.key
        });
    };

    switch (col.fieldType) {
        case 'enum':
        case 'bool':
            return <Dropdown
                clearable={!col.isRequired}
                className={className || 'notifications__input'}
                noResultsMessage={'Результатов не найдено'.t}
                selection
                name={col.key}
                value={valueToString(row[col.key])}
                onChange={handleChange}
                fluid
                closeOnChange
                options={col.fieldType === 'bool' ? BooleanOptions : (col.items || []).map(v => ({...v, value: v.value.toString()}))}
            />;
        case 'checkbox':
            return <div className='checkbox-field'>
                <Checkbox
                    name={col.key}
                    checked={!!row[col.key]}
                    onChange={(e, {name, checked}) => handleChange(null, {
                        name,
                        value: checked
                    })}
                />
            </div>;
        case 'enums':
            return <Dropdown
                clearable={!col.isRequired}
                className={className || 'notifications__input'}
                noResultsMessage={'Результатов не найдено'.t}
                selection
                name={col.key}
                value={row[col.key]}
                onChange={handleChange}
                fluid
                closeOnChange={false}
                multiple
                options={(col.items || [])}
            />;
        case 'multiselect':
            return <Select
                className={className || ''}
                clearable={!col.isRequired}
                handleChange={handleChange}
                col={col}
                row={row}
                multiselect
            />;
        case 'select':
        case 'withError':
            return <Select
                className={className || ''}
                clearable={!col.isRequired}
                handleChange={handleChange}
                col={col}
                row={row}
                minLettersForSearch={1}
            />;
        case 'time':
            return <InputTime
                className={className || 'notifications__input'}
                name={col.key}
                value={row[col.key]}
                onChange={handleChange}
            />;
        case 'number':
        case 'text':
        default:
            return <input
                type={col.isInteger ? 'text' : col.fieldType}
                className={className || 'notifications__input'}
                name={col.key}
                value={row[col.key]}
                onChange={onChange}
                maxLength={col.maxLength}
            />;
    }
};