import React, {useEffect, useState} from 'react';
import {
    getFieldsData,
    getForm,
    getTask,
    loadWaybills,
    processFileById,
    saveDemoFile,
    saveForm,
    saveRule as saveRuleRequest,
    updateTask
} from "../../api/importForms";
import {Dimmer, Loader} from "semantic-ui-react";
import debounce from "awesome-debounce-promise";
import ResizeDoublePage from "../../components/resizeDoublePage";
import FileBlock from "./FileBlock";
import {DisabledFormStatuses, ProcessingTypesEnum, serviceParametersForRules} from "../../api/model/ImportForms";
import {copyObject} from "../../services/utils";
import {getValueByTypeForSave, getValueByTypeForView} from "./formRules/valuesConvert";
import {checkInValid} from "./formRules/validation";
import {tableTabs} from "../../components/bigTable/const";
import FormBlock from "./FormBlock";
import {dictionaryIsNotEmpty} from "../../api/dictionaries";
import {DictionariesEnum, DictionariesSettings} from "../../api/model/Dictionaries";

const saveWithDebounce = debounce((form) => saveForm(form), 1000);
const saveRuleWithDebounce = debounce((id, form) => saveRuleRequest(id, form), 1000);

const ImportFormEdit = ({
                            history,
                            fmid,
                            id,
                            setNavi,
                            taskId,
                            onlyTableMode = false,
                            header,
                            onChangeErrors,
                            isOnlyView
                        }) => {
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [fieldsData, setFieldsData] = useState([]);
    const [errors, setErrors] = useState({});
    const [schedulesIsNotEmpty, setSchedulesIsNotEmpty] = useState(false);

    const [isFileLoading, setIsFileLoading] = useState(false);
    const [tablesData, setTablesData] = useState(tableTabs.slice(0, 2));
    const [taskData, setTaskData] = useState({});
    const [errorsBlockData, setErrorsBlockData] = useState([]);

    const fileId = taskId || form.formFileTaskId;
    const fileStatus = taskData.taskStatus || form.formFileStatus;
    const formFileName = taskData.taskFileName || form.formFileName;

    const fileIsBlocked = DisabledFormStatuses.includes(fileStatus);
    const onlyViewMode = isOnlyView || form.isBlocked || fileIsBlocked;
    const companyId = form.companyFmid;

    const setFile = (name, data) => {
        setTablesData(tablesData => {
            const newTablesData = [...tablesData];

            const index = newTablesData.findIndex(t => t.key === name);
            if (index > -1) {
                if (data) {
                    newTablesData[index] = {
                        ...newTablesData[index],
                        ...data
                    };
                } else {
                    newTablesData.splice(index, 1);
                }
            } else if (data) {
                const newTable = tableTabs.find(t => t.key === name);
                newTablesData.push({
                    ...newTable,
                    ...data
                });
            }

            return newTablesData;
        });
    };

    const getTaskData = (id, withoutRefresh) => {
        setIsFileLoading(true);
        getTask(id)
            .then(data => {
                if (data) {
                    tableTabs.forEach(tab => {
                        setFile(
                            tab.key,
                            data[tab.key]
                                ? copyObject({
                                    tableData: data[tab.key]
                                }) : undefined
                        );
                    });
                    if (taskId) {
                        setTaskData({
                            taskStatus: data.status,
                            taskFileName: data.filename,
                        });
                    }
                    setErrorsBlockData((data || {}).messages || []);
                }
                if (DisabledFormStatuses.includes((data || {}).status)) {
                    setTimeout(() => getTaskData(fileId, withoutRefresh), 10000);
                } else {
                    !withoutRefresh && getFormData();
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsFileLoading(false);
            });
    };

    useEffect(() => {
        fileId && getTaskData(fileId, !onlyViewMode);
    }, [fileId]);

    const saveFile = (file) => {
        let formData = new FormData();
        formData.append(`file`, file[0]);

        saveDemoFile(id, formData).then((res) => {
            getFormData();
            getTaskData(res, true);
        });
    };

    const saveValue = (data) => {
        data.length && updateTask(fileId, data)
            .then(r => console.log(r))
            .catch(() => getTaskData(fileId));
    };

    const getFields = () => {
        getFieldsData().then(res => {
            res && setFieldsData([
                ...serviceParametersForRules.map(p => ({
                    ...p,
                    isServiceParameter: true
                })),
                ...res
            ]);
        });
    };

    useEffect(() => {
        getFields();
    }, []);

    const getValueForView = (val, rule) => {
        return getValueByTypeForView(
            val.processingType,
            val.values,
            val.processingType === ProcessingTypesEnum.REPLACE_CUSTOM && (fieldsData.find(f => f.columnName === rule.columnName) || {}).type
        );
    };

    const checkSchedules = async () => {
        const name = DictionariesSettings.find(h => h.key.toString() === DictionariesEnum.SCHEDULES).urlName;
        const res = await dictionaryIsNotEmpty(name, id);
        setSchedulesIsNotEmpty(!!res);
    };

    const getFormData = () => {
        setLoading(true);
        getForm(id)
            .then(res => {
                const rules = {};
                serviceParametersForRules.forEach(v => {
                    rules[v.columnName] = {
                        ruleType: res[v.columnName]
                    };
                });

                (res.data || []).forEach(rule => {
                    if (rule.stages) {
                        const ruleValues = rule.stages;
                        const firstRuleValue = ruleValues.length && ruleValues[0];
                        if (firstRuleValue) {
                            rules[rule.columnName] = {
                                ruleType: firstRuleValue.processingType,
                                value: getValueForView(firstRuleValue, rule)
                            };
                        }
                        const othersRules = [];
                        if ((ruleValues || []).length > 1) {
                            ruleValues.slice(1).forEach(r => {
                                othersRules.push({
                                    ruleType: r.processingType,
                                    value: getValueForView(r, rule)
                                });
                            });
                        }
                        rules[rule.columnName].rules = othersRules;
                    }
                });
                setForm({
                    ...res,
                    rules
                });

                const scheduleRule = Object.keys(rules).find(ruleKey => rules[ruleKey].ruleType === ProcessingTypesEnum.SCHEDULE);
                !!scheduleRule && checkSchedules();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        (fieldsData.length && id) && getFormData();
    }, [id, fieldsData]);

    useEffect(() => {
        setNavi(form, taskData);
    }, [form.name, form.companyName, taskData]);

    const setValue = (e, {name, value}) => {
        setForm(form => ({
            ...form,
            [name]: value
        }));
        setIsChanged(true);
    };

    const save = (data, onSave) => {
        const allData = {
            id: form.id,
            name: form.name
        };

        serviceParametersForRules.forEach(v => {
            allData[v.columnName] = ((form.rules || {})[v.columnName] || {}).ruleType;
        });

        saveWithDebounce({
            ...allData,
            ...data
        }).then(() => {
            setIsChanged(false);
            onSave && onSave();
        });
    };

    const saveRule = (data, noDebounce) => {
        const func = noDebounce ? saveRuleRequest : saveRuleWithDebounce;
        const save = () => func(form.id, data).then(() => {
            setIsChanged(false);
        }).catch(e => {
            e && getTaskData(fileId);
        });

        !noDebounce
            ? save()
            : setTimeout(() => save(), 1500);
    };

    useEffect(() => {
        (id && isChanged) && save({
            name: form.name
        });
    }, [form.name]);

    const setRuleValue = (name, value, noDebounce) => {
        setForm(form => {
            return {
                ...form,
                rules: {
                    ...(form.rules || {}),
                    [name]: value
                }
            };
        });

        const isServiceParameter = serviceParametersForRules.map(v => v.columnName).includes(name);

        if (isServiceParameter) {
            save({
                [name]: (value || {}).ruleType
            });
        } else if (!value) {
            saveRule(
                {
                    columnName: name,
                    stages: null
                },
                noDebounce
            );
        } else {
            const allRules = [
                value,
                ...(value.rules || [])
            ];

            saveRule(
                {
                    columnName: name,
                    stages: allRules.map(value => ({
                        processingType: value.ruleType,
                        values: getValueByTypeForSave(
                            value.ruleType,
                            value.value,
                            value.ruleType === ProcessingTypesEnum.REPLACE_CUSTOM && (fieldsData.find(f => f.columnName === name) || {}).type
                        )
                    }))
                },
                noDebounce
            );
        }
    };

    const setNewStatus = () => {
        setLoading(true);
        save({
            publish: true
        }, () => {
            setLoading(false);
            history.push(`/import/${fmid}`);
        });
    };

    useEffect(() => {
        const errors = checkInValid(fieldsData, form.rules || {}, schedulesIsNotEmpty);
        setErrors(errors);
    }, [form.rules, fieldsData, schedulesIsNotEmpty]);

    const isValid = form.name && Object.keys(errors).every(key => !errors[key]);

    const processFile = () => {
        processFileById(fileId)
            .then(() => {
                getFormData();
                getTaskData(fileId);
            });
    };

    const getHeight = () => {
        return document.documentElement.clientHeight - 108;
    };

    const loadWaybillsFunc = () => loadWaybills(taskId);

    const goToWaybillGrid = () => history.push(`/waybills`);

    const defaultActiveIndex = () => {
        if (tablesData.find(t => t.key === tableTabs[2].key) && (!errorsBlockData || !errorsBlockData.length)) {
            return 2;
        } else {
            return fileId && 1;
        }
    };

    const page1 = (height) => <FileBlock
        defaultActiveIndex={defaultActiveIndex()}
        disabled={onlyViewMode}
        height={height}
        isValid={isValid}
        processFile={processFile}
        saveFile={saveFile}
        tablesData={tablesData}
        saveValue={saveValue}
        isLoading={isFileLoading}
        isFileExists={fileId}
        onlyTableMode={onlyTableMode}
        loadWaybills={loadWaybillsFunc}
        goToWaybillGrid={goToWaybillGrid}
        initData={{
            waybillImportFormId: id
        }}
        errors={errorsBlockData}
    />;

    const page2 = (_, height) => <FormBlock
        formId={id}
        saveForm={setNewStatus}
        fieldsData={fieldsData}
        companyId={companyId}
        form={form.rules || {}}
        height={height}
        disabled={onlyViewMode}
        setValue={setRuleValue}
        isValid={isValid}
        errors={errors}
        loading={loading}
        checkSchedules={checkSchedules}
        fmid={fmid}
    />;

    return (
        <div className="import-form-edit">
            <Dimmer active={loading} inverted>
                <Loader/>
            </Dimmer>
            {
                header && header({
                    disabled: onlyViewMode,
                    form,
                    formFileName,
                    fileIsBlocked,
                    setValue
                })
            }
            <div className="import-form-edit__main">
                {onlyTableMode
                    ? page1(getHeight())
                    : <ResizeDoublePage
                        page1={page1}
                        page2={page2}
                    />}
            </div>
        </div>
    );
};

export default ImportFormEdit;