import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import O from '../Option';
import './InputDropdown.css';


InputDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(O))
};

export default function InputDropdown({
                                          value,
                                          options = [],
                                          onChange,
                                          handleAdd,
                                          clearable = false,
                                          placeholder,
                                          style,
                                          disabled,
                                          multiple,
                                          fluid,
                                          text,
                                          closeOnChange = true,
                                          id,
                                          search = false
                                      }) {
    function handleChange(e, {value}) {
        onChange(value);
    };

    // function handleAdd(e, { value }) {
    //     setOptions([new O(value, value), ...options]);
    //     setValue(value);
    // }

    return (
        <Dropdown
            id={`dropdown_${id || Date.now()}`}
            text={text}
            noResultsMessage={'Результатов не найдено'.t}
            allowAdditions={!!handleAdd}
            style={style}
            className="InputDropdown"
            placeholder={placeholder || 'te'}
            value={value}
            onChange={handleChange}
            onAddItem={handleAdd}
            closeOnChange={closeOnChange}
            search={search || !!handleAdd}
            selection
            fluid={fluid}
            multiple={multiple}
            clearable={clearable}
            options={options}
            disabled={disabled}
        />
    );
}

