import React, {useContext, useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import { Dropdown, Confirm } from 'semantic-ui-react';
import ModalProxy from '../../../../components/modals/ModalProxy';
import { PointStatusEnum } from '../../../../api/model/Point';
import PointModalReject from './PointModalReject';
import PointModalCorrect from './PointModalCorrect';
import PointModalApprove from './PointModalApprove';
import T from '../../../../components/Translate';
import PointModalReplace from './PointModalReplace';
import PointModalAccept from './PointModalAccept';
import {pointDelete, pointsExportReport, pointRegister, pointSetLocked} from '../../../../api/points';
import ActionMenu from '../../../../components/buttons/ActionMenu';
import ActionPointRemove from '../../../points/pointsToolbar/ActionPointRemove';
import {ContextUser} from "../../../../services/context";
import UserPermissions from "../../../../api/model/UserPermissions";
import {toast} from "../../../../services/toast";

const PointViewHeaderActions = ({ point, fetchData, saveData, backToList, setLoading, isClient, companyId, history, errors }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);

    const approved = point && point.statusId === PointStatusEnum.ACCEPTED;
    const onApproval = point && point.statusId === PointStatusEnum.ON_APPROVAL;
    const isError = point && point.exportStatus === 2; // PointStatusEnum.APPROVAL_ERROR;
    const onCorrection = point && point.statusId === PointStatusEnum.NEEDS_CORRECTION;
    const rejected = point && point.statusId === PointStatusEnum.REJECTED;
    const unexported = point && !onApproval && !onCorrection && !rejected && [0, 2].includes(point.exportStatus);
    const exported = point.exportStatus === 1;

    const contextUser = useContext(ContextUser);
    const viewLockedBtns = contextUser.current.permissions.includes(UserPermissions.POINTS);
    const isLocked = point.isLocked;

    async function handleConfirm() {

        setConfirmOpen(false);

        const deleted = await pointDelete(point.id);

        if (deleted) {
            backToList();
        }
    }

    async function setLocked(locked) {
        setLoading(true);
        try {
            await pointSetLocked(point.id, {locked});
            await fetchData();
        } finally {
            setLoading(false);
        }
    }

    async function doExport() {
        setLoading(true);
        try {
            !((errors || []).length) ? saveData().then(async d => {
                if (d){
                    await pointsExportReport(point.id);
                    await fetchData();
                }
            }) : toast.error('Неверные данные');
        } finally {
            setLoading(false);
        }
    }


    async function doRegister() {
        setLoading(true);
        try {
            saveData().then(async d => {
                if (d){
                    await pointRegister(point.id, {searchName: point.fmid.trim(), zone: point.zone});
                    await fetchData();
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const lockButtons = () => {
        return viewLockedBtns && <>
            <Dropdown.Item disabled={isLocked} onClick={()=>setLocked(true)}><T>Заблокировать адрес</T></Dropdown.Item>
            <Dropdown.Item disabled={!isLocked} onClick={()=>setLocked(false)}><T>Разблокировать адрес</T></Dropdown.Item>
        </>;
    };

    if (isClient) {
        return <ActionMenu>
            <ActionPointRemove 
                selectedRows={[{companyId, pointId: point.id}]} 
                updateRows={() => {history.push('/points');}}>
                <Dropdown.Item disabled={isLocked}><T>Удалить</T></Dropdown.Item>
            </ActionPointRemove>
            {onCorrection && 
                <ModalProxy
                    data={point}
                    onOk={() => { history.push('/points'); }}
                    content={PointModalApprove}
                >
                    <Dropdown.Item disabled={isLocked}><T>Завершить корректировку</T></Dropdown.Item>
                </ModalProxy>
            }
        </ActionMenu>;
    }

    if (!exported) {
        return (
            <ActionMenu>
                
                {(onApproval || onCorrection) &&
                    <ModalProxy
                        data={point}
                        onOk={backToList}
                        content={PointModalReject}
                    >
                        <Dropdown.Item disabled={isLocked}><T>Отклонить</T></Dropdown.Item>
                    </ModalProxy>
                }
                
                {(onApproval || onCorrection || rejected) &&
                    <Dropdown.Item disabled={isLocked} onClick={doRegister}><T>Зарегистрировать</T></Dropdown.Item>
                }
                
                {(onApproval || rejected) && <ModalProxy
                    data={point}
                    onOk={backToList}
                    content={PointModalCorrect}>
                    <Dropdown.Item disabled={isLocked}><T>Требуется уточнение</T></Dropdown.Item>
                </ModalProxy>}

                {(onApproval || rejected) &&
                    <ModalProxy data={point} content={PointModalReplace} onOk={() => history.push("/admin/points")}>
                        <Dropdown.Item disabled={isLocked}><T>Заменить на существующий</T></Dropdown.Item>
                    </ModalProxy>
                }

                {(approved || isError) && <Dropdown.Item disabled={isLocked} onClick={doExport}><T>Зарегистрировать</T></Dropdown.Item>}
                
                {(rejected || approved || (onApproval && !point.useCount)) &&
                    <>
                        <Dropdown.Item disabled={isLocked} onClick={() => setConfirmOpen(true)}><T>Удалить</T></Dropdown.Item>
                        <Confirm 
                            header={"Удаление адреса".t}
                            content={"Удалить адрес?".t} 
                            dimmer="inverted"
                            open={confirmOpen} 
                            onCancel={() => setConfirmOpen(false)} onConfirm={handleConfirm} 
                        />
                    </>
                }
                {lockButtons()}
            </ActionMenu>
        );
    } else {
        return (
            <ActionMenu>
                {(onApproval || onCorrection || isError) && <ModalProxy
                    data={point}
                    onOk={backToList}
                    content={PointModalReject}
                >
                    <Dropdown.Item disabled={isLocked}><T>Отклонить</T></Dropdown.Item>
                </ModalProxy>}
                
                {(onApproval || isError || rejected) && <ModalProxy
                    data={point}
                    onOk={backToList}
                    content={PointModalCorrect}>
                    <Dropdown.Item disabled={isLocked}><T>На корректировку</T></Dropdown.Item>
                </ModalProxy>}

                {(onApproval || onCorrection || isError || rejected) &&
                    <ModalProxy data={point} onOk={backToList} content={PointModalAccept}>
                        <Dropdown.Item disabled={isLocked}><T>Зарегистрировать</T></Dropdown.Item>
                    </ModalProxy>
                }
                {(unexported || approved) &&
                    <Dropdown.Item disabled={isLocked} onClick={doExport}><T>Зарегистрировать</T></Dropdown.Item>
                }
                {(!(onApproval || onCorrection || isError)) && 
                    <>
                        <Dropdown.Item disabled={isLocked} onClick={() => setConfirmOpen(true)}><T>Удалить</T></Dropdown.Item>
                        <Confirm content={"Удалить адрес?".t} open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={handleConfirm} />
                    </>
                }
                {lockButtons()}
            </ActionMenu>
        );
    }


};

export default withRouter(PointViewHeaderActions);