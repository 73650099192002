import debounce from 'awesome-debounce-promise';
import React, {useContext, useEffect, useState} from 'react';
import {Form, Grid, GridColumn, Input, Table} from 'semantic-ui-react';
import {LegalFormOptions} from '../../../../api/model/Company';
import Point, {LoadCapacityOptions, LoadPositionOptions, PointTypeEnum} from '../../../../api/model/Point';
import UserPermissions from '../../../../api/model/UserPermissions';
import {
    getActivitiesRequest,
    getPlatformsRequest,
    pointClientSearchNameUpdate,
    pointMappingCodeUpdate
} from '../../../../api/pointsClient';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import O from '../../../../components/Option';
import T from '../../../../components/Translate';
import {ContextUser} from '../../../../services/context';
import {RecordData, TableRecord} from '../../../waybills/wbView/record/Record';
import PointFormDays from '../pointForm/PointFormDays';
import InputDropdownMultiple from "../../../../components/inputs/InputDropdownMultiple";
import {toast} from "../../../../services/toast";
import InputSelectMultiple from "../../../../components/inputs/InputSelectMultiple";
import {mappingCodeTrim} from "../../../../services/utils";


const DefaultCompanyTypeOptions = [
    new O(PointTypeEnum.SHOP, "Магазин"),
    new O(PointTypeEnum.DISTRIBUTION_CENTER, "РЦ"),
    new O(PointTypeEnum.RECEPIENT, "Контрагент"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "Склад клиента")
];

const HubCompanyTypeOptions = [
    new O(PointTypeEnum.FM, "Кросс-док"),
];

const updateSearchNameDebounced = debounce((id, companyId, searchName) => pointClientSearchNameUpdate(id, companyId, searchName), 1200);
const updateMappingCodeDebounced = debounce((id, companyId, mappingCode) => pointMappingCodeUpdate(id, companyId, mappingCode), 1200);

export function DataCell({title, text}) {
    return <Table.Cell><TableRecord data={new RecordData(title, text)}/></Table.Cell>;
}

/**  */
export default function PointViewTabAddrParams({point, updatePoint, isClient, companyId, errors, setErrors}) {

    const contextUser = useContext(ContextUser);

    const [legalFormOptions, setLegalFormOptions] = useState(buildLegalFormOptions());
    const hasPermissionFmidAddress = contextUser.current.permissions.includes(UserPermissions.FMID_ADDRESS);
    const hasPermissionMappingCode = contextUser.current.permissions.includes(UserPermissions.MAPPING_CODE);
    const [platformOptions, setPlatformOptions] = useState([]);
    const [activityOptions, setActivityOptions] = useState([]);

    useEffect(() => {
        getPlatforms();
    }, []);

    useEffect(() => {
        point.platformId && getActivities();
    }, [point.platformId]);

    const getPlatforms = async () => {
        const res = await getPlatformsRequest();
        const platforms = res ? res.platforms : [];

        setPlatformOptions(platforms.map(i => new O(i.id, i.name)));
    };

    const getActivities = async () => {
        const data = await getActivitiesRequest(point.platformName);

        setActivityOptions(((data || {}).activities || []).map(i => new O(i.id, i.name)))
    };

    useEffect(() => {
        const errorsNew = getErrorsForm();
        setErrors(errorsNew)
    }, [point, activityOptions, platformOptions]);

    useEffect(() => {
        if (errors.includes('dockCapacity')) {
            toast.warning('Поле "Грузоподъемность" обязательно к заполнению'.t);
        }
    }, []);

    function getErrorsForm() {
        let errors = [];

        if (!point.dockCapacity || point.dockCapacity && !point.dockCapacity.length) {
            errors.push('dockCapacity')
        }
        if (isError('platform', point) || (point.platformId && !(platformOptions || []).find(o => o.value === point.platformId))) errors.push('platformId');
        if (isError('activity', point) || (point.activityId && !(activityOptions || []).find(o => o.value === point.activityId))) errors.push('activityId');
        return errors;
    }

    function handleLegalFormOptionAdd(e, {value}) {
        setLegalFormOptions([new O(value, value), ...legalFormOptions]);
    }

    function buildLegalFormOptions() {
        const value = point.companyLegalForm;
        const custom = !LegalFormOptions.some(item => item.value === value);
        if (custom) {
            return [...LegalFormOptions, new O(value, value)];
        }
        return LegalFormOptions;
    };

    function update(prop) {
        const pointChanged = new Point({...point, ...prop});
        updatePoint(pointChanged);
    };

    function updateClientSearchName(searchName, notSave = false) {
        const pointChanged = new Point({...point, ...{pointName: searchName}});
        updatePoint(pointChanged, false);
        !notSave && updateSearchNameDebounced(point.id, companyId, searchName.trim()); // eslint-disable-line
    }

    function updateMappingCode(mappingCode) {
        mappingCode = mappingCodeTrim(mappingCode);
        const pointChanged = new Point({...point, ...{mappingCode}});
        updatePoint(pointChanged, false);
        updateMappingCodeDebounced(point.id, companyId, mappingCode); // eslint-disable-line
    }

    const contact = (point.contacts && point.contacts.length && point.contacts[0]) || {};

    const hasPermissions = contextUser.current.permissions.includes(UserPermissions.POINTS);

    const defaultTypeOption = hasPermissions ?
        [...DefaultCompanyTypeOptions, ...HubCompanyTypeOptions] :
        DefaultCompanyTypeOptions;

    const isLocked = point.isLocked;

    const optionsByName = (name) => {
        let items;
        switch (name) {
            case 'platform': {
                items = platformOptions;
                break;
            }
            case 'activity': {
                items = activityOptions;
                break;
            }
            default: items = [];
        }
        return items;
    };

    const isError = (name, point) => {
        const items = optionsByName(name);
        const item = items.find(o => o.text === point[`${name}Name`]);
        return point[`${name}Name`] && !(item && item.value === point[`${name}Id`]);
    };

    const nameById = (name, id) => {
        const items = optionsByName(name);
        const item = items.find(o => o.value === id);
        return item ? item.text : null;
    };

    return (
        <Form>
            <Grid columns='equal' divided='vertically'>
                <Grid.Row style={{paddingBottom: 0}}>
                    <GridColumn>
                        <Form.Group>
                            <Form.Field
                                width="4"
                                required
                            >
                                <label><T>Юр. форма</T></label>
                                <InputDropdown
                                    disabled={isClient}
                                    allowAdditions
                                    placeholder={"Юр. форма".t}
                                    options={legalFormOptions}
                                    value={point.companyLegalForm}
                                    onChange={(value, text) => update({
                                        companyLegalForm: value,
                                    })}
                                    handleAdd={handleLegalFormOptionAdd}
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                width="12"
                            >
                                <label><T>Грузополучатель</T></label>
                                <Input
                                    disabled={isClient}
                                    placeholder={'Грузополучатель'.t}
                                    value={point.companyName}
                                    onChange={e => update({companyName: e.target.value})}
                                />
                            </Form.Field>
                        </Form.Group>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row>
                <GridColumn>
                    <Form.Field required>
                            <label><T>Тип грузополучателя</T></label>
                        <InputDropdown
                                placeholder={"Тип грузополучателя".t}
                            options={defaultTypeOption}
                            value={point.pointType}
                            onChange={val => update({pointType: val})}
                            disabled={isClient}
                        />
                    </Form.Field>
                </GridColumn>
                    {
                        point.pointType === PointTypeEnum.FM &&
                        <>
                            <GridColumn>
                            <Form.Field error={errors.includes('platformId')}>
                                <label><T>Платформа</T></label>
                                <InputDropdown
                                    text={point.platformName}
                                    placeholder={"Выбрать платформу".t}
                                    options={platformOptions}
                                    fluid
                                    clearable
                                    value={point.platformId}
                                    onChange={val => update({platformId: val, platformName: nameById('platform', val), activityId: null, activityName: null})}
                                    disabled={isClient && isLocked}
                                />
                            </Form.Field>
                            </GridColumn>
                            <GridColumn>
                            <Form.Field error={errors.includes('activityId')}>
                                <label><T>Активность</T></label>
                                <InputDropdown
                                    text={point.activityName}
                                    placeholder={"Выбрать активность".t}
                                    options={activityOptions}
                                    disabled={!point.platformId || (isClient && isLocked)}
                                    clearable
                                    value={point.activityId}
                                    onChange={val => update({activityId: val, activityName: nameById('activity', val)})}
                                />
                            </Form.Field>
                            </GridColumn>
                        </>
                    }
                </Grid.Row>
                {isClient && <Grid.Row className="p-t-0">
                    <>
                        <GridColumn>
                            <Form.Field>
                                <label><T>{isClient ? 'Название для быстрого поиска'.t : 'Название адреса в системе Клиента'}</T></label>
                                <Input
                                    placeholder={isClient ? 'Название для быстрого поиска'.t : 'Название адреса в системе Клиента'.t}
                                    value={point.pointName || ''}
                                    onChange={e => updateClientSearchName(e.target.value.trimStart())}
                                    onBlur={e => updateClientSearchName(e.target.value.trim(), true)}
                                    disabled={isClient && isLocked}
                                />
                            </Form.Field>
                        </GridColumn>

                        {
                            hasPermissionMappingCode &&
                            <GridColumn>
                                <Form.Field>
                                    <label><T>Код для маппинга</T></label>
                                    <InputSelectMultiple
                                        placeholder={"Код для маппинга".t}
                                        value={point.mappingCode}
                                        onChange={(e, {value}) => {
                                            updateMappingCode(value);
                                        }}
                                        disabled={isClient && isLocked}
                                    />
                                </Form.Field>
                            </GridColumn>
                        }
                    </>
                </Grid.Row>}
                {hasPermissionFmidAddress && <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label><T>Название адреса в системе FM</T></label>
                            <Input
                                disabled={true}
                                placeholder={"fmid адреса".t}
                                value={point.fmid}
                                onChange={(e) => update({fmid: e.target.value.trimStart()})}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>}
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required={!!contact.phone}>
                            <label><T>Контактное лицо</T></label>
                            <Input
                                placeholder={'Контактное лицо'.t}
                                value={contact.name || ''}
                                onChange={e => update({
                                    contacts: [{...contact, name: e.target.value}]
                                })}
                                disabled={isClient && isLocked}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field required={!!contact.name}>
                            <label><T>Телефон</T></label>
                            <Input
                                placeholder={'Телефон'.t}
                                value={contact.phone || ''}
                                onChange={e => update({
                                    contacts: [{...contact, phone: e.target.value}]
                                })}
                                disabled={isClient && isLocked}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field required error={errors.includes('dockCapacity')}>
                            <label><T>Грузоподъемность</T></label>
                            <InputDropdownMultiple
                                disabled={isClient}
                                placeholder={"Грузоподъемность".t}
                                options={LoadCapacityOptions}
                                value={point.dockCapacity}
                                onChange={val => update({dockCapacity: val})}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field>
                            <label><T>Сторона загрузки</T></label>
                            <InputDropdown
                                disabled={isClient && isLocked}
                                placeholder={"Сторона загрузки".t}
                                options={LoadPositionOptions}
                                value={point.dockPosition}
                                onChange={val => update({dockPosition: val})}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <PointFormDays
                            disabled={isClient && isLocked}
                            days={point.days}
                            onChange={val => update({days: val})}
                        />
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Field>
                            <label><T>Комментарий</T></label>
                            <Input
                                placeholder={'Комментарий'.t}
                                value={point.comment || ''}
                                onChange={e => update({comment: e.target.value})}
                                disabled={isClient && isLocked}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
}
