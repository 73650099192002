import React, {useEffect, useMemo, useState} from 'react';
import {Button, Dropdown, Form, Icon, Input, Menu, MenuHeader, Message, Modal, TextArea, Step} from "semantic-ui-react";
import InputDropdown from "../../components/inputs/InputDropdown";
import {CargoTypeOptions, WeekDayOptions} from "../../api/model/Enums";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import SchedulesConstructor from "../../components/schedulesConstructor";
import {nanoid} from "nanoid";
import {getPointsFromSchedule} from "../../api/points";
import {getCompaniesFromSchedule} from "../../api/companies";
import SelectClientMulti from "../../components/inputs/SelectClientMulti";
import {normalizeTime} from "../../services/normalizeTime";
import {restrictionOnlyNumber} from "../../services/utils";
import InputDate from "../../components/inputs/InputDate";
import {
    createSchedule,
    editSchedule,
    getChainOptionsRequest,
    getCityOptionsRequest,
    getConsigneeOptionsRequest,
    getRegionsOptionsRequest,
    getSchedule,
    getSearchnamesOptionsRequest,
    getSendingregionsOptionsRequest
} from "../../api/schedules";
import ScheduleHistory from "./scheduleHistory";

const AddScheduleModal = ({open, onClose, id, fetchData}) => {

    const consigneeOthersValue = 'Другие грузополучатели';

    let [form, setForm] = useState({});
    let [items, setItems] = useState([{nanoId: nanoid()}]);
    let [points, setPoints] = useState([]);
    let [companies, setCompanies] = useState([]);
    let [isDubleSave, setIsDubleSave] = useState({open: false});

    let [saveLoader, setSaveLoader] = useState(false);

    let [chainOptions, setChainOptions] = useState([]);
    let [chainSearch, setChainSearch] = useState('');

    let [consigneeOptions, setConsigneeOptions] = useState([]);
    let [consigneeSearch, setConsigneeSearch] = useState('');

    let [searchnameOptions, setSearchnameOptions] = useState([]);
    let [searchnameSearch, setSearchnameSearch] = useState('');

    let [sendingregionsOptions, setSendingregionsOptions] = useState([]);
    let [sendingregionsSearch, setSendingregionsSearch] = useState('');

    let [regionsOptions, setRegionsOptions] = useState([]);
    let [regionsSearch, setRegionsSearch] = useState('');

    let [cityOptions, setCityOptions] = useState([]);
    let [citySearch, setCitySearch] = useState('');


    const clearSearch = () => {
        setChainSearch('');
        setSearchnameSearch('');
        setSendingregionsSearch('');
        setRegionsSearch('');
        setCitySearch('');
    };


    const handleClose = () => {
        setForm({});
        setItems([{nanoId: nanoid()}]);
        onClose();
        fetchData();
        clearSearch();
    };

    useEffect(() => {
        if (open) {
            getPointsRequest();
            getSearchNameOptions();
            getSendingregionsOptions();
            getRegionsOptions();
            //getCityOptions();
            id && getFormRequest();
        }
    }, [open]);

    useEffect(() => {
        getChainOptions();
    }, [chainSearch, form.chain]);

    useEffect(() => {
        (form.chain && !(Object.keys(form.chain).length)) && handleChange(null, {value: true, name: 'consigneeDisabled'});
    }, [form.chain]);

    useEffect(() => {
        (form.consignee && form.consignee !== consigneeOthersValue) && handleChange(null, {value: true, name: 'chainDisabled'});
    }, [form.consignee]);

    useEffect(() => {
        getConsigneeOptions();
    }, [consigneeSearch, form.consignee, form.chain]);

    useEffect(() => {
        getSearchNameOptions();
    }, [searchnameSearch, form.chain, form.searchnamePointId]);

    useEffect(() => {
        getSendingregionsOptions();
    }, [sendingregionsSearch, form.sendingRegion]);

    useEffect(() => {
        getRegionsOptions();
    }, [regionsSearch]);

    useEffect(() => {
        getCityOptions();
    }, [citySearch, form.destinationRegion]);

    const getChainOptions = async () => {
        const {chains = []} = await getChainOptionsRequest(chainSearch, {});
        setChainOptions([
            {
                key: 'null',
                value: {},
                text: 'Другие грузополучатели',
            },
            ...chains.map(i => ({
                key: nanoid(),
                value: {
                    companyName: i.companyName,
                    pointType: i.pointType,
                    name: i.name,
                    networkId: i.network ? i.network.id : null,
                    network: i.network
                },
                text: i.name
            }))
        ]);
    };

    const getConsigneeOptions = async () => {
        const {consignees = []} = await getConsigneeOptionsRequest(consigneeSearch, {network: (form.chain || {}).name});
        setConsigneeOptions([
            ...consignees.map(i => ({
                key: nanoid(),
                value: i.consignee,
                text: i.consignee,
                network: i.network,
            }))
        ]);
        if (!consigneeSearch && form.consignee && !(consignees.find(v => v.consignee === form.consignee))) {
            handleChange(null, {value: null, name: 'consignee'});
        }
    };

    const getSearchNameOptions = async () => {
        const {searchnames = []} = await getSearchnamesOptionsRequest({
            searchname: searchnameSearch,
            ...form.chain,
            currentSearchnamePointId: form.searchnamePointId,
        });

        setSearchnameOptions(searchnames.map(i => ({
            ...i,
            key: i.pointId,
            value: i.pointId,
            text: i.searchname,
        })))
    };

    const getSendingregionsOptions = async () => {
        const {regions = []} = await getSendingregionsOptionsRequest(sendingregionsSearch, form.sendingRegion);

        setSendingregionsOptions(regions.map(i => ({
            key: i,
            value: i,
            text: i,
        })))
    };

    const getRegionsOptions = async () => {
        if (!form.searchnamePointId) {
            const {regions = []} = await getRegionsOptionsRequest(regionsSearch);

            setRegionsOptions(regions.map(i => ({
                key: nanoid(),
                value: i.name,
                text: i.name,
                id: i.id,
                postalCodes: i.postalCodes,
            })))
        }
    };

    const getCityOptions = async () => {
        if (form.destinationRegion && !form.searchnamePointId) {
            const {cities = []} = await getCityOptionsRequest((regionsOptions.find(i => i.value === form.destinationRegion) || {}).id, citySearch);

            setCityOptions(cities.map(i => ({
                key: nanoid(),
                value: i.name,
                text: i.name,
            })))
        }
    };

    const isDisabledSaveBtn = useMemo(() => {
        let isDisabled = false;

        const requiredFields = ['chain','consignee', 'cargoTypes', 'companies',
            'sendingRegion', 'destinationRegion', 'deadlineDay', 'deliveryDay', 'daysRoad'];

        requiredFields.forEach(field => {
            if (form[field] === undefined || form[field] === null || form[field] === '') isDisabled = true
        });

        if (form.cargoTypes && !form.cargoTypes.length) isDisabled = true;
        if (form.companies && !(form.companies.isAll || form.companies.ids.length)) isDisabled = true;

        if (
            items.find(
                item =>
                    !item.pointId ||
                    item.deliveryDay === undefined ||
                    item.sendingDay === undefined
            )
        )
            isDisabled = true;

        return isDisabled;
    }, [form, items]);

    const getFormRequest = async () => {
        const result = await getSchedule(id);

        let form = {};

        Object.keys(result).forEach(key => {
            if (key === 'chain' && result[key] === null) {
                form = {
                    ...form,
                    chain: {}
                }
            } else if (key === 'searchname') {
                form = {
                    ...form,
                    searchnamePointId: result[key] && result[key].pointId
                }
            } else if (key !== points) {
                form = {
                    ...form,
                    [key]: result[key]
                }
            }
        });

        setForm(form);
        setItems(result.points);
    };

    const getPointsRequest = async () => {
        const points = await getPointsFromSchedule();

        setPoints(points.map(point => ({key: point.id, text: point.fmid, value: point.id})));
    };

    const getCompaniesRequest = async (page = 1, search = '', isSearch) => {
        let companiesNext = await getCompaniesFromSchedule(page, search);

        companiesNext = companiesNext.map(company => ({key: company.fmid, text: company.fmid, value: company.fmid}));

        setCompanies([...(!isSearch ? companies : []), ...companiesNext])
    };

    const handleChange = (e, {value, name}) => {
        let valueObj = {
            [name]: value
        };

        clearSearch();

        if (name === 'chain') {
            valueObj.consigneeDisabled = false;

            if (value && !(Object.keys(value).length)) {
                valueObj.consignee = consigneeOthersValue;
                valueObj.consigneeDisabled = true;
            } else if (!value || (value && Object.keys(value).length)) {
                valueObj.consignee = null;
                valueObj.consigneeDisabled = false;
            }
        }

        if (name === 'searchnamePointId') {
            if (value) {
                const search = searchnameOptions.find(i => i.value === value) || {};
                valueObj = {
                    ...valueObj,
                    destinationRegion: search.region,
                    destinationCity: [search.settlement],
                    destinationPostalCodes: search.postalCodes
                }
            } else {
                valueObj = {
                    ...valueObj,
                    destinationRegion: '',
                    destinationCity: '',
                    destinationPostalCodes: []
                }
            }
        }

        if (name === 'destinationRegion') {
            valueObj = {
                ...valueObj,
                destinationCity: ''
            };

            if (value) {
                valueObj = {
                    ...valueObj,
                    destinationPostalCodes: (regionsOptions.find(i => i.value === value) || {}).postalCodes
                };
            } else {
                valueObj = {
                    ...valueObj,
                    destinationPostalCodes: []
                };
            }
        }

        if (name === 'sendingRegion') {
            setItems(prevState => {
                const list = [...prevState];
                list[0] = {
                    ...list[0],
                    pointId: (points.find(i => i.text === value) || {}).value,
                };

                return list
            })
        }

        if (name === 'consignee') {
            valueObj.chainDisabled = false;
            if (value === consigneeOthersValue) {
                valueObj.chain = {};
                valueObj.consigneeDisabled = true;
            } else {
                const el = consigneeOptions.find(v => v.value === value);
                if (el && el.network) {
                    const c = chainOptions.find(v => v.value?.name === el.network);
                    if (c) valueObj.chain = c.value;
                    valueObj.chainDisabled = true;
                }
            }

        }

        setForm(form => ({
            ...form,
            ...valueObj,
        }))
    };

    const handleChangeViewPeriod = (e, {value, name}) => {
        setForm(form => ({
            ...form,
            showPeriod: {
                ...form.showPeriod,
                [name]: value,
            }
        }))
    };

    const mapData = () => {
        return {
            ...form,
            chain: form.chain && Object.keys(form.chain).length ? form.chain : null,
            points: items,
        }
    };

    const dubleConfirmCheck = async (number) => {
        setIsDubleSave({
            open: true,
            number,
            cancel: () => {
                setIsDubleSave({
                    open: false,
                })
            },
            confirm: () => {
                setIsDubleSave({
                    open: false,
                });
                handleSave();
            }
        });
    };

    useEffect(() => {
        setActiveTab(0);
    }, [form.id]);

    /*const handleCreate = async () => {
        setSaveLoader(true);
        try {
            let checkResult;
            if (id) {
                checkResult = await editCheck(id, mapData());
            } else {
                checkResult = await createCheck(mapData())
            }
            if (!checkResult) {
                handleSave()
            } else {
                dubleConfirmCheck(checkResult)
            }

        } finally {
            setSaveLoader(false)
        }
    };
*/
    const handleSave = async () => {
        setSaveLoader(true);
        try {
            if (!id) {
                await createSchedule(mapData());
            } else {
                await editSchedule(id, mapData());
            }

            handleClose();
        } finally {
            setSaveLoader(false)
        }
    };

    const [activeTab, setActiveTab] = useState(0);

    return (
        <Modal
            centered={false}
            open={open}
            closeOnDimmerClick={false}
            onClose={handleClose}
        >
            <Modal.Header>{id ? "Редактирование графика" : "Добавить график"}</Modal.Header>

            {form.id && <Menu tabular>
                <Menu.Item className="schedule-edit__menu-item" active={activeTab === 0}
                           onClick={() => setActiveTab(0)}>
                    <Icon name="info" size="big"/>
                    <MenuHeader>График</MenuHeader>
                </Menu.Item>
                <Menu.Item className="schedule-edit__menu-item" active={activeTab === 1}
                           onClick={() => setActiveTab(1)}>
                    <Icon name="history" size="big"/>
                    <MenuHeader>История изменений</MenuHeader>
                </Menu.Item>
            </Menu>}

            {activeTab === 0 && <Modal.Content scrolling>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Регион отправки</label>
                            <Dropdown
                                fluid
                                name="sendingRegion"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setSendingregionsSearch(searchQuery)}
                                options={sendingregionsOptions}
                                placeholder='Значение'
                                search
                                clearable
                                searchQuery={sendingregionsSearch}
                                selection
                                value={form.sendingRegion}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Регион доставки</label>
                            {
                                form.searchnamePointId
                                    ?
                                    <Input disabled value={form.destinationRegion}/>
                                    : <Dropdown
                                        fluid
                                        name="destinationRegion"
                                        onChange={handleChange}
                                        onSearchChange={(e, {searchQuery}) => setRegionsSearch(searchQuery)}
                                        options={regionsOptions}
                                        placeholder='Значение'
                                        search
                                        clearable
                                        disabled={form.searchnamePointId}
                                        searchQuery={regionsSearch}
                                        selection
                                        value={form.destinationRegion}
                                    />
                            }
                        </Form.Field>
                        <Form.Field>
                            <label>Город доставки</label>
                            {
                                form.searchnamePointId
                                    ? <Input disabled value={Array.isArray(form.destinationCity) ? form.destinationCity.join(', ') : form.destinationCity} />
                                    :  <Dropdown
                                        fluid
                                        name="destinationCity"
                                        className="InputDropdown"
                                        onChange={handleChange}
                                        onSearchChange={(e, {searchQuery}) => setCitySearch(searchQuery)}
                                        options={cityOptions}
                                        placeholder='Значение'
                                        multiple
                                        search
                                        clearable
                                        disabled={!form.destinationRegion}
                                        searchQuery={citySearch}
                                        selection
                                        value={form.destinationCity}
                                    />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Сеть</label>
                            <Dropdown
                                fluid
                                name="chain"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setChainSearch(searchQuery)}
                                options={chainOptions}
                                placeholder='Значение'
                                search
                                clearable
                                searchQuery={chainSearch}
                                selection
                                value={form.chain}
                                disabled={form.chainDisabled}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Грузополучатель</label>
                            <Dropdown
                                fluid
                                name="consignee"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setConsigneeSearch(searchQuery)}
                                options={consigneeOptions}
                                placeholder='Значение'
                                search
                                clearable
                                searchQuery={consigneeSearch}
                                selection
                                value={form.consignee}
                                disabled={form.consigneeDisabled}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Searchname</label>
                            <Dropdown
                                fluid
                                name="searchnamePointId"
                                onChange={handleChange}
                                onSearchChange={(e, {searchQuery}) => setSearchnameSearch(searchQuery)}
                                options={searchnameOptions}
                                placeholder='Значение'
                                search
                                clearable
                                searchQuery={searchnameSearch}
                                selection
                                value={form.searchnamePointId}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal" style={{alignItems: "flex-end"}}>
                        <Form.Field>
                            <Form.Group widths="equal">
                                <Form.Field required>
                                    <label>Клиент</label>
                                    <SelectClientMulti
                                        options={companies}
                                        value={form.companies}
                                        multiple
                                        placeholder="Значение"
                                        fetchData={getCompaniesRequest}
                                        onClose={() => setCompanies([])}
                                        onChange={val => handleChange(null, {value: val, name: "companies"})}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>Тип груза</label>
                                    <InputDropdown
                                        options={CargoTypeOptions}
                                        value={form.cargoTypes}
                                        multiple
                                        placeholder="Значение"
                                        onChange={val => handleChange(null, {value: val, name: "cargoTypes"})}
                                        closeOnChange={false}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                        <Form.Field>
                            <label>Рекомендованное временное окно</label>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>C</label>
                                    <Input
                                        value={form.recommendedTimeFrom ? form.recommendedTimeFrom.slice(0, 5) : ''}
                                        name="recommendedTimeFrom"
                                        maxLength={200}
                                        placeholder="Значение"
                                        onChange={(e, {value, name}) => handleChange(e, {
                                            value: normalizeTime(value),
                                            name
                                        })}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>По</label>
                                    <Input
                                        value={form.recommendedTimeTo ? form.recommendedTimeTo.slice(0, 5) : ''}
                                        name="recommendedTimeTo"
                                        maxLength={200}
                                        placeholder="Значение"
                                        onChange={(e, {value, name}) => handleChange(e, {
                                            value: normalizeTime(value),
                                            name
                                        })}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Крайний день размещения заявки</label>
                            <InputDropdown
                                options={WeekDayOptions}
                                value={form.deadlineDay}
                                placeholder="Значение"
                                onChange={val => handleChange(null, {value: val, name: "deadlineDay"})}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>День доставки грузополучателю</label>
                            <InputDropdown
                                options={WeekDayOptions}
                                value={form.deliveryDay}
                                placeholder="Значение"
                                onChange={val => handleChange(null, {value: val, name: "deliveryDay"})}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Дни в пути</label>
                            <Input
                                value={form.daysRoad}
                                name="daysRoad"
                                maxLength={200}
                                placeholder="Значение"
                                onChange={(e, {value, name}) => handleChange(e, {
                                    value: restrictionOnlyNumber(value),
                                    name
                                })}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider/>
                <SchedulesConstructor
                    items={items}
                    points={points}
                    onChange={setItems}
                />
                <Divider/>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Комментарий</label>
                            <TextArea
                                value={form.comment || ''}
                                name="comment"
                                onChange={handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Доп. информация для клиента</label>
                            <TextArea
                                value={form.additionalInformationForClient || ''}
                                name="additionalInformationForClient"
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Период показа</label>
                        <Divider/>
                    </Form.Field>
                    <Form.Group>
                        <Form.Field>
                            <label>Начиная с</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChangeViewPeriod(null, {name: "startView", value})
                                }
                                value={form.showPeriod && form.showPeriod.startView}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Включительно до</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChangeViewPeriod(null, {name: "endView", value})
                                }
                                value={form.showPeriod && form.showPeriod.endView}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>}

            {activeTab === 1 && <Modal.Content scrolling>
                <ScheduleHistory id={form.id}/>
            </Modal.Content>}

            <Modal.Actions>
                <Button onClick={handleClose}>Отменить</Button>
                <Button primary loading={saveLoader} disabled={isDisabledSaveBtn}
                        onClick={handleSave}>{id ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Actions>
            <Modal open={isDubleSave.open}>
                <Modal.Header>Проверка на дубли</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>{`График ${isDubleSave.number} уже существует в системе`}</div>
                            <div>Чтобы подтвердить создание дубля нажмите на кнопку "Сохранить",
                                если не хотите подтверждать действие, то нажмите на кнопку "Отмена".
                            </div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={isDubleSave.cancel}
                    >
                        Отмена
                    </Button>
                    <Button
                        color="green"
                        onClick={isDubleSave.confirm}
                    >
                        Добавить
                    </Button>
                </Modal.Actions>
            </Modal>
        </Modal>
    );
};

export default AddScheduleModal;
